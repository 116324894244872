<template>
    <div>
        <div :class="hasContent" class="form-group form-input">
            <input
                    :id="id"
                    ref="formInput"
                    v-model="value"
                    :class="this.type === 'password' ? 'password-input' : ''"
                    :inputmode="inputMode"
                    :pattern="pattern"
                    :placeholder="placeholder"
                    :type="type === 'password' ? passwordType : type"
                    autocomplete="off"
                    @blur="onBlur"
                    @focus="onFocus"
                    @input="onInput"/>
            <i v-if="type === 'password'" class="form-input__show-password"
               @click.prevent="hidePassword = !hidePassword">
                <icon-base :view-box="[0, 0, 24, 24]" height="100%" icon-name="show/hide" width="20px">
                    <icon-hide v-show="!hidePassword"/>
                    <icon-show v-show="hidePassword"/>
                </icon-base>
            </i>
        </div>
        <p v-if="showError" class="error-msg">{{ errorMsg }}</p>
    </div>
</template>

<script>
    import misc from "../../utils/misc";

    export default {
        name: "FormInput",
        props: {
            type: {
                type: String,
                default: "text",
            },
            label: {
                type: String,
                default: "",
            },
            id: {
                type: String,
                required: true,
            },
            placeholder: {
                type: String,
                default: "",
            },
            defaultValue: {
                type: [Number, String],
                required: false
            },
        },

        data() {
            return {
                value: "",
                hasContent: null,
                pattern: null,
                inputMode: null,
                showError: false,
                errorMsg: null,
            };
        },
        mounted() {
            if (this.defaultValue) {
                this.value = this.defaultValue
                this.onInput()
            }
            if (this.value) {
                this.hasContent = 'has-content'
            }
        },
        methods: {
            updateClass: function (event) {
                if ((event.type === "focus") || (event.type === "blur" && this.value)) {
                    this.hasContent = "has-content";
                } else {
                    this.hasContent = "";
                }
            },
            onFocus: function (e) {
                this.$emit("focus");
                this.updateClass(e);
            },
            onBlur: function (e) {
                this.$emit("blur");
                this.showError = !!this.errorMsg;
                this.updateClass(e);
            },

            validateEmail(email) {
                return misc.validateEmail(email)
            },

            validateUrl(url) {
                return misc.validateUrl(url)
            },

            onInput: function () {
                if (this.type === "email") {
                    if (!this.value) {
                        this.errorMsg = null;
                    } else if (this.validateEmail(this.value)) {
                        this.errorMsg = null;
                    } else {
                        this.errorMsg = "Please enter a valid email address";
                    }
                }
                if (this.type === "url") {
                    if (!this.value) {
                        this.errorMsg = "The input field is empty";
                    } else if (this.validateUrl(this.value)) {
                        this.errorMsg = null;
                    } else {
                        this.errorMsg = "Please enter a valid url";
                    }
                }
                this.emitValue();
            },

            emitValue: function () {
                if (this.type === "email") {
                    this.$emit("input", {
                        value: this.value,
                        isValid: !this.errorMsg,
                    });
                }
                if (this.type === "url") {
                    this.$emit("input", {
                        value: this.value,
                        isValid: !this.errorMsg,
                    });
                } else {
                    this.$emit("input", this.value);
                }
                this.$emit("change", this.value);
            },

            created() {
                if (this.inputValue) this.value = this.inputValue.toString();
                if (this.value) {
                    this.hasContent = "has-content";
                }
            },

            updated() {
                if (this.value) {
                    this.hasContent = "has-content";
                }
            },
        },
        computed: {
            passwordType() {
                return this.hidePassword ? 'password' : 'text'
            },
        }
    };
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/form-input";
</style>
