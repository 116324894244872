import Repository from "./Repository";

const resource = "oauth/token";
const querystring = require('querystring');

export default {
    getAccessToken() {
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };
        const body = {
            grant_type: "client_credentials",
            client_id: process.env.VUE_APP_CLIENT_ID,
            client_secret: process.env.VUE_APP_CLIENT_SECRET
        };
        return Repository.post(`${resource}`, querystring.stringify(body), config);
    }
}