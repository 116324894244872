import DataRepository from "@/repo/DataRepository";
import AuthRepository from "@/repo/AuthRepository";
import ClientRepository from "@/repo/ClientRepository";
import PartnersRepository from "./PartnersRepository";

const repositories = {
	data: DataRepository,
	auth: AuthRepository,
	client: ClientRepository,
	partners: PartnersRepository
};

export const RepositoryFactory = {
	get: name => repositories[name]
};