<template>
    <div>
        <section class="homepage">
            <HomeHeader  :isHomePage="false" />
        </section>
        <section class="signup section-spacing">
            <div class="container">
                <div class="row">
                    <main class="col col-md-8 p-5 border">
                        <div class="pb-2 border-bottom">
                            <p class="display__title">
                                Hire Early Career Tech Professionals matched with your technology, 
                                industry and company culture.
                            </p>
                            <p class="display__header-text sub-text">
                            Our Developers, Designer and Product mangers are waiting to help you with your 
                            project. Let us know what your needs are. 
                            </p>
                        </div>
                        <div>
                            <RegisterForm />
                        </div>
                    </main>
                    <aside class="col col-md-4 aside">
                        <p class="mb-5">They decided to work with us:</p>
                        <div>
                            <BrandLogos 
                            parentClass="aside-images gap-3" 
                            brandClass="col-5 mb-5" />
                        </div>
                        <div class="p-5">
                            <TestimonialCard extraClass="text-black text-font-sm" />
                        </div>
                    </aside>
                </div>
            </div>
        </section>

        <HomeFooter/>
    </div>
</template>

<script>
import HomeHeader from "../layouts/HomeHeader.vue";
import RegisterForm from "../forms/RegisterForm"
import BrandLogos from "./BrandLogos"
import TestimonialCard from "./TestimonialCard"
import HomeFooter from "../layouts/HomeFooter"

export default {
    name: "Signup",
    components: {
        HomeHeader,
        RegisterForm,
        BrandLogos,
        TestimonialCard,
        HomeFooter
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/signup.scss"
</style>