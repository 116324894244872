<template>
    <div v-if="display">
        <div class="homepage bg-white">
            <HomeHeader
                :links="links"
                :is-partner=true
                :partnersLogo="partnersLogo"
                :partner-color="partnerColor"
                linkClass="nav-link text-dark-blue"
                iconColor="dark"/>
            <Hero :isSupportLogo=true headerText="text-dark-blue" subText="text-soft-black" :bg-color="partnerColor"/>
        </div>

        <OurTalents/>

        <WhyChooseUs/>

        <TalentsPool :partner-color="partnerColor"/>

        <HowItWorks/>

        <div class="text-black bg-light-gray text-center section-spacing">
            <div class="container">
                <img alt="growth isometric image" class="img-fluid" src="../assets/img/icons/growth-isometric.svg"/>
                <p class="display__title text-center py-5">
                    Start building that product or hiring your next team member &#128521;
                </p>
                <router-link class="btn btn-primary btn-brand-primary btn-lg"
                             to="/get-started/service-preference"
                             :style="{backgroundColor: partnerColor, borderColor: partnerColor}">
                    Get started now
                </router-link>
            </div>
        </div>

        <HomeFooter :isSupportLogoFooter = true />
    </div>
</template>

<script>
    import HomeHeader from "../components/layouts/HomeHeader";
    import Hero from "../components/elements/Hero";
    import OurTalents from "../components/elements/OurTalents";
    import WhyChooseUs from "../components/elements/WhyChooseUs";
    import TalentsPool from "../components/elements/TalentsPool";
    import HowItWorks from "../components/elements/HowItWorks";
    import HomeFooter from "../components/layouts/HomeFooter";
    import {mapMutations, mapActions, mapState} from 'vuex';
    import store from "../store";
    import constants from "../utils/constants";

    export default {
        name: "PartnersPage",
        components: {
            HomeHeader,
            Hero,
            OurTalents,
            WhyChooseUs,
            TalentsPool,
            HowItWorks,
            HomeFooter,
        },
        created() {
            this.getPartnerRequirement(this.$route.params.key)
            this.unsubscribe = store.subscribe(mutation => {
                if (mutation.type === "partnersModule/setPartner" && this.partner) {
                    if (this.partner.status === constants.success) {
                        this.partnersLogo = this.partner.data.logo_url;
                        this.partnerColor = this.partner.data.color;
                        this.setReturnURL(this.$route.path)
                        this.display = true;
                    } else if (this.partner.status === constants.error) {
                        this.$router.push({name: "Page404"});
                    } else {
                        this.getPartnerRequirement(this.$route.params.key)
                    }
                }
            })
        },
        data: () => {
            return {
                display: false,
                partnersLogo: '/brands/dufuna-talent-logo2.svg',
                partnerColor: null,
                links: [
                    {
                        input: "Why choose us?",
                        href: "#whyChooseUs",
                    },
                    {
                        input: "How it works",
                        href: "#howItWorks",
                    },
                    {
                        input: "Contact",
                        href: "#contact",
                    },
                ],
            }
        },
        computed: {
            ...mapState('partnersModule', {
                partner: `partner`
            })
        },
        methods: {
            ...mapMutations({
                setReturnURL: `setReturnURL`
            }),
            ...mapMutations('partnersModule', {
                setPartner: 'setPartner'
            }),
            ...mapActions('partnersModule', {
                getPartnerRequirement: `getPartnerRequirementByKey`
            })
        },
        destroyed() {
            this.unsubscribe();
        }
    };
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/homepage.scss";
</style>
