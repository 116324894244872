function getDefaultState() {
	return {
		countryState: [],
		industryCategoryState: [],
		cities: [],
		jobRoles: [],
		technicalSkills: [],
		error: {
			title: "",
			message: ""
		},
		alert: null,
		token: "",
		returnURL: ""
	}
}

export default {
	getDefaultState,
}