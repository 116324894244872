<template>
    <div class="text-center form-spacing">
        <span class="form-title">What resources are you looking to hire?</span>

        <div class="my-5">
            <div v-for="(requiredTalent, index) in requiredTalents" :key="index"
                 class="form-group required-talent-form pt-3 mb-3">
                <div class="row gx-2">
                    <div class="col-md-9">
                        <form-select id="requiredTalent"
                                     ref="talent"
                                     :default-value="requiredTalent.selectedRole"
                                     :options="jobRoles"
                                     @change="onChange"
                                     @input="requiredTalent.selectedRole = $event">
                        </form-select>
                    </div>
                    <counter v-model="requiredTalent.numberOfTalents"
                            :default-count="requiredTalent.numberOfTalents"/>
                </div>

                <div class="col-md-12 mb-3">
                    <select-two
                            ref="skills"
                            :options="technicalSkills"
                            :settings="{ multiple: true, placeholder: 'Enter Skills' }"
                            class="d-grid"
                            :value="requiredTalent.selectedStack"
                            @change="requiredTalent.selectedStack = $event"/>
                </div>

                <div v-if="index >= 1" class="d-flex justify-content-center">
                    <p class="pointer text-red" @click="removeField(index)">
                        <ion-icon name="remove-circle-outline"/>
                        Remove
                    </p>
                </div>
            </div>

            <div class="d-flex talent-options">
                <p class="pointer text-blue" @click="addField">
                    <ion-icon name="add-circle-outline"/>
                    Add more
                </p>
            </div>

            <button :class="[isValid ? 'enabled' : 'disabled']"
                    class="btn btn-primary btn-lg"
                    type="button"
                    @click="proceed">
                <span class="btn__text-spacing">Next question </span> 
                <ion-icon name="arrow-forward" />
            </button>
        </div>
    </div>
</template>

<script>
    import FormSelect from "../forms/FormSelect";
    import Select2 from "v-select2-component";
    import Counter from "../elements/Counter";
    import {mapMutations, mapState} from "vuex";

    export default {
        name: "RequiredTalents",
        components: {
            "form-select": FormSelect,
            "select-two": Select2,
            Counter,
        },
        data: function () {
            return {
                isValid: false,
                requiredTalents: [],

            };
        },
        mounted() {
            this.$emit("currentProgress",
                    {
                        current: 2,
                        total: 4
                    });

            const talents = this.jobRequestValues.requiredTalents;
            talents?.length ? this.requiredTalents = [...talents] : this.addField();
        },
        methods: {
            emitValue: function () {
                this.$emit("input", {
                    requiredTalents: this.requiredTalents,
                });
            },
            proceed: function () {
                this.updateRequiredTalents(this.requiredTalents);
                this.$emit("proceed");
            },
            onChange: function () {
                this.validate();
                this.emitValue();
            },
            validate: function () {
                this.isValid = !!this.requiredTalents;
            },
            addField() {
                this.requiredTalents.push({
                    selectedRole: 1,
                    selectedStack: [],
                    numberOfTalents: 1
                });
            },
            removeField(index) {
                this.$delete(this.requiredTalents, index);
            },
            ...mapMutations('getStartedModule', {
                updateRequiredTalents: 'updateRequiredTalents'
            }),
        },
        computed: {
            ...mapState({
                technicalSkills: `technicalSkills`,
                jobRoles: `jobRoles`
            }),
            ...mapState('getStartedModule', {
                jobRequestValues: `jobRequestValues`
            }),
        },
    };
</script>

<style lang="scss">
@import "@/assets/scss/components/form-input";
</style>
