<template>
    <div class="col-md-3">
        <div class="d-flex p-2 counter-body mb-3">
            <button class="counter" type="button" v-on:click="subtractNum">-</button>
            <span class="mx-3">{{ count }}</span>
            <button class="counter" type="button" v-on:click="addNum">+</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Counter",
        data() {
            return {
                count: 1,
            };
        },
        props: {
          defaultCount:{
              type: Number,
              required: false
          }
        },
        mounted() {
            if (this.defaultCount) {
                this.count = this.defaultCount
                this.emitValue();
            }
        },
        methods: {
            emitValue() {
                this.$emit("input", this.count);
            },
            addNum: function () {
                this.count += 1;
                this.emitValue();
            },
            subtractNum: function () {
                this.count = this.count === 1 ? 1 : this.count - 1;
                this.emitValue();
            },
        },
    };
</script>
