<template>
    <div id="whyChooseUs" class="features section-spacing">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-5 py-4 border-top">
                    <p class="display__title py-2 px-3">
                        Here is why we <span class="text-blue-gradient">recommend</span> and
                        advice you start
                        <span class="text-blue-gradient">working</span> with us.
                    </p>
                </div>

                <div class="col-12 col-md-7 px-4">
                    <div class="px-5" v-for="(feature, index) in features" :key="index">
                        <div class="features__content-body">
                            <span class="features__emoji">
                                <i class="bi text-blue-gradient" :class="feature.classIcon" />
                            </span>

                            <div class="ml-3">
                                <h5 class="card__header-text">{{ feature.title }}</h5>
                                <p class="card__sub-text">{{ feature.text }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "WhyChooseUs",
        data: () => ({
            features: [
                {
                    classIcon: "bi-chat-square-text",
                    title: "Rigorous Selection Process",
                    text: "All our early career professionals have gone through a rigorous selection process.",
                },
                {
                    classIcon: "bi-people",
                    title: "Affordable & Scalable",
                    text: "Build your MVP at an affordable price and hire the team (if you like) after delivery.",
                },
                {
                    classIcon: "bi-umbrella",
                    title: "Advisory",
                    text: "All projects are supervised and led by experienced senior professionals.",
                },
                {
                    classIcon: "bi-telephone-outbound",
                    title: "Support",
                    text: "We are always available to serve you better. Connect with us through any of our support channels.",
                },
            ],
        }),
    };
</script>

<style lang="scss">
@import "@/assets/scss/components/why-choose-us.scss";
</style>
