<template>
    <div class="text-center form-spacing">
        <p class="mb-5 form-title">Is it a new or existing product?</p>

        <div class="row g-3 mb-5">
            <div v-for="(product, index) in products" :key="index" class="col-6 d-flex">
                <div class="form-picker">
                    <input :id="product.value"
                           v-model="selectedProductType"
                           :value="product.value"
                           class="d-none"
                           name="serviceCategory"
                           type="radio"
                    />
                    <label :for="product.value">
                        <img :alt="product.name" :src="product.illustration" class="img-fluid"/>
                        <span class="mb-0 label">{{ product.label }}</span>
                    </label>
                </div>
            </div>
        </div>

        <button :disabled="!selectedProductType" class="btn btn-primary btn-lg " type="button" @click="proceed">
            <span class="btn__text-spacing">Next question </span> 
            <ion-icon name="arrow-forward" />
        </button>
    </div>
</template>

<script>
    import {mapMutations, mapState} from "vuex";

    export default {
        name: "ProductCategory",
        data() {
            return {
                selectedProductType: null,
                products: [{
                    label: "New product",
                    name: "new product",
                    value: "new-product",
                    illustration: "/illustrations/group-5.svg",
                },
                    {
                        label: "Existing product",
                        name: "existing product",
                        value: "existing-product",
                        illustration: "/illustrations/group-4.svg",
                    },
                ],
            };
        },
        mounted() {
            this.$emit("currentProgress",
                    {
                        current: 2,
                        total: 7
                    });
            this.selectedProductType = this.jobRequestValues.productType;
        },
        methods: {
            proceed: function () {
                this.updateProductType(this.selectedProductType)
                this.$emit("proceed");
            },
            ...mapMutations('getStartedModule', {
                updateProductType: 'updateProductType'
            })
        },
        computed: {
            ...mapState('getStartedModule', {
                jobRequestValues: `jobRequestValues`
            })
        }
    };
</script>
