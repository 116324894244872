import {RepositoryFactory} from '@/repo/RepositoryFactory';
import {utils} from '@/utils';
import NProgress from "nprogress";

const partnersRepository = RepositoryFactory.get('partners');
const constants = utils.constants;
const responseCodes = constants.responseCodes;

const getPartnerRequirementByKey = async (context, partnerKey) => {
    context.commit('setPartner', null);
    NProgress.start();
    await partnersRepository.getPartnerRequirementByKey(context.rootState.token, partnerKey)
        .then(response => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseStatus === responseCodes.successCode && responseData.status === constants.success) {
                context.commit('setPartner', responseData);
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(context, 'partnersModule/getPartnerRequirementByKey', partnerKey);
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                context.commit('setPartner', responseData);
                utils.misc.showGeneralError(context, responseData.message);
            }
            NProgress.done();
        }).catch(() => {
            utils.misc.catchNetworkError(context);
            NProgress.done();
        })
}

export default {
    getPartnerRequirementByKey
}
