const updateError = (state, payload) => {
	state.error = {
		title: payload.title,
		message: payload.message
	}
};

const updateAlert = (state, payload) => {
	if (payload) {
		state.alert = {
			title: payload.title,
			message: payload.message,
			buttons: payload.buttons
		}
	} else {
		state.alert = payload
	}
};

const countriesMutation = (state, payload) => {
	state.countryState = payload
};

const setJobRoles = (state, jobRoles) => {
	state.jobRoles = addValuesToLOV(jobRoles)
};
const setTechnicalSkills = (state, technicalSkills) => {
	technicalSkills.forEach(skill => {
		delete Object.assign(skill, {['text']: skill['name'] })['name'];
	})
	state.technicalSkills = addValuesToLOV(technicalSkills)
};
const setCities = (state, cities) => {
	state.cities = addValuesToLOV(cities)
};

const updateTokenMutation = (state, token) => {
	state.token = token
};

const addValuesToLOV = (LOV) => {
	LOV.map(item => item.value = parseInt(item.id));
	return LOV
}
const setReturnURL = (state, returnURL) => {
	state.returnURL = returnURL
}

const industryCategoryMutation = (state, payload) => {
	state.industryCategoryState = payload;
}

export default {
	updateError,
	updateAlert,
	countriesMutation,
	setJobRoles,
	setCities,
	setTechnicalSkills,
	setReturnURL,
	updateTokenMutation,
	industryCategoryMutation
}