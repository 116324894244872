const countriesGetters = (state) => {
	return state.countryState;
}

const industryCategoryGetters = (state) => {
	return state.industryCategoryState;
}

export default {
	countriesGetters,
	industryCategoryGetters
}