import mutations from './mutations'
import actions from "./actions";

const getDefaultGetStartedStates = () => {
	return {
		jobRequestValues: {},
		jobRequestState: {},
		clientObject: {},
		isRegistered: false,
	}
};

const state = getDefaultGetStartedStates();

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getDefaultGetStartedStates
}