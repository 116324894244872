<template>
    <div class="container get-started">
        <div class="col-12 d-flex get-started-bar align-items-center">
            <div class="col-md-2">
            <span v-if="progressBar.current > 1" class="d-flex pointer get-started__close-icon"
                  @click="previous">
                <ion-icon name="chevron-back" size="large"/>Previous
            </span>
            </div>
            <div class="col-md-8">
                <FormProgressBar :current="progressBar.current" :total="total">
                </FormProgressBar>
            </div>
            <div class="col-md-2">
                <router-link :to="returnURL">
                    <span class="d-flex ms-3 get-started__close-icon">
                        <ion-icon name="close" size="large"/>Close
                    </span>
                </router-link>
            </div>
        </div>

        <form id="getStarted" ref="getStartedForm" autocomplete="on">
            <router-view :ref="currentRef"
                         v-bind="currentProperties"
                         @change="onChange($event)"
                         @currentProgress="progressBar = $event"
                         @proceed="proceed"
            />
        </form>
    </div>
</template>

<script>
    import FormProgressBar from "../components/forms/FormProgressBar";
    import router from "../router";
    import {mapState} from 'vuex';

    export default {
        name: "GetStarted",
        router,
        components: {
            FormProgressBar
        },
        data() {
            return {
                isVisible: false,
                form: this.$refs.getStartedForm,
                progressBar: {
                    current: 1,
                    total: 7,
                },
                selectedRequestType: 'build-a-product',
            };
        },
        methods: {
            onChange: function (event) {
                if (this.currentComponent === 'ServicePreference')
                    this.selectedRequestType = event
            },
            proceed: function () {
                this.$router.push({name: this.destination})
            },
            previous: function () {
                this.$router.push({name: this.origin})
            }
        },
        computed: {
            total() {
                return this.selectedRequestType === "build-a-product" ? 7 : 4;
            },
            currentComponent: function () {
                return this.$route.name
            },
            currentRef: function () {
                let firstLetter = this.currentComponent.charAt(0);
                return this.currentComponent.replace(firstLetter, firstLetter.toLowerCase())
            },
            currentProperties: function () {
                if (this.currentComponent === 'ContactInfo') {
                    return {selectedRequestType: this.selectedRequestType};
                } else {
                    return null
                }
            },
            destination: function () {
                switch (this.currentComponent) {
                    case 'ServicePreference': {
                        if (this.selectedRequestType === 'build-a-product')
                            return 'ProductCategory'
                        else return 'RequiredTalents'
                    }
                    case 'ProductCategory':
                        return 'BusinessPurpose'
                    case 'BusinessPurpose':
                        return 'ClientTarget'
                    case 'ClientTarget':
                        return 'SimilarProducts'
                    case 'SimilarProducts':
                        return 'ProjectEstimation'
                    case 'ProjectEstimation':
                        return 'ContactInfo'
                    case 'RequiredTalents':
                        return 'JobType'
                    case 'JobType':
                        return 'ContactInfo'
                    default:
                        return 'null'
                }
            },
            origin: function () {
                switch (this.currentComponent) {
                    case 'ProductCategory':
                        return 'ServicePreference'
                    case 'BusinessPurpose':
                        return 'ProductCategory'
                    case 'ClientTarget':
                        return 'BusinessPurpose'
                    case 'SimilarProducts':
                        return 'ClientTarget'
                    case 'ProjectEstimation':
                        return 'SimilarProducts'
                    case 'RequiredTalents':
                        return 'ServicePreference'
                    case 'JobType':
                        return 'RequiredTalents'
                    case 'ContactInfo':
                        if (this.selectedRequestType === 'build-a-product')
                            return 'ProjectEstimation'
                        else return 'JobType'
                    default:
                        return 'null'
                }
            },
            ...mapState(['returnURL', 'googleToken'])
        },
        beforeDestroy() {
            this.siteKey = null
        }
    }
</script>

<style lang="scss">
@import "@/assets/scss/pages/get-started.scss";
</style>
