import Repository from "@/repo/Repository";

const resource = 'client';
const config = {
	headers: {
		'Content-Type': 'application/json'
	}
};

const createClientAndJobRequest = (
	clientEmail,
	clientName,
	clientBizName,
	clientNumber,
	clientInfo,
	clientIndustry,
	clientCountry,
	clientRequestType,
	accessToken
	) => {
	const params = {
		access_token: accessToken,
	};
	const body = {
		email: clientEmail,
		contact_name: clientName,
		business_name: clientBizName,
		phone_number: clientNumber,
		additional_information: clientInfo,
		industry_category_id: clientIndustry,
		country_id: clientCountry,
		request_type: clientRequestType
	}
	return Repository.post(`${resource}/register`, body, {params}, config)
};

export default {
	createClientAndJobRequest,
}