<template>
    <div class="text-center form-spacing">
        <span class="form-title">What problems are you trying to solve?</span>
        <p class="mb-5">
            in 250 words or less. <span>({{ wordCount }} words remaining) </span>
        </p>

        <div class="form-row mb-5">
            <div class="form-group form-picker d-flex justify-content-center">
        <textarea id="solution" v-model="businessPurpose" class="form-control input" placeholder="At RavenCo, We believe that humans are…"
                  rows="6"/>
            </div>
        </div>
        <button :disabled="wordCount >= 0 ? !businessPurpose : businessPurpose"
                class="btn btn-primary btn-lg"
                type="button"
                @click="proceed">
            <span class="btn__text-spacing">Next question </span> 
            <ion-icon name="arrow-forward" />
        </button>
    </div>
</template>

<script>
    import {mapMutations, mapState} from "vuex";

    export default {
        name: "BusinessPurpose",
        data() {
            return {
                businessPurpose: "",
            };
        },
        mounted() {
            this.$emit("currentProgress",
                    {
                        current: 3,
                        total: 7
                    });
            this.businessPurpose = this.jobRequestValues.problemStatement || this.businessPurpose
        },
        methods: {
            proceed: function () {
                this.updateProblemStatement(this.businessPurpose);
                this.$emit("proceed");
            },
            ...mapMutations('getStartedModule', {
                updateProblemStatement: 'updateProblemStatement'
            })
        },
        computed: {
            wordCount() {
                const count = this
                let fileCount = count.businessPurpose;
                let limit = 250;
                return (
                        count.skip_html && (fileCount = fileCount.replace(/<\S[^><]*>/gi, "")),
                                count.businessPurpose.match(/\w+/g) ? limit - count.businessPurpose.match(/\w+/g).length : 250
                );
            },
            ...mapState('getStartedModule', {
                jobRequestValues: `jobRequestValues`
            })
        },
    };
</script>
