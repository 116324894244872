import Repository from "@/repo/Repository";

const resource = 'data';
const config = {
	headers: {
		'Content-Type': 'application/json'
	}
}

const getCountries = (accessToken) => {
	const params = {
		access_token: accessToken,
	}
	return Repository.get(`${resource}/countries`, {params}, config);
};

const getIndustryCategory = (accessToken) => {
	const params = {
		access_token: accessToken,
	}
	return Repository.get(`${resource}/industry-categories`, {params}, config);
}

const getTechnicalSkills = (accessToken) => {
	const params = {
		access_token: accessToken,
	}
	return Repository.get(`${resource}/technical-skills`, {params}, config);
}

const getJobRoles = (accessToken) => {
	const params = {
		access_token: accessToken,
	}
	return Repository.get(`${resource}/job-roles`, {params}, config);
}

export default {
	getCountries,
	getIndustryCategory,
	getTechnicalSkills,
	getJobRoles,
}