<template>
    <div :class="parentClass">
        <div
            v-for="(brand, index) in brands"
            :key="index"
            :class="brandClass"
            >
                <img
                    :src="brand.image"
                    :alt="brand.name"
                    class="img-fluid"
                    loading="lazy"
                />
        </div>
    </div>
</template>

<script>
export default {
    name: "BrandLogos",
    props: {
        parentClass: {
            type: String,
        },
        brandClass: {
            type: String,
        }
    },
    data: () => ({
        brands: [
            {
                image: require("@/assets/img/brand/zazu.svg"),
                name: "Zazu",
            },
            {
                image: require("@/assets/img/brand/dg.svg"),
                name: "DEG Merkle",
            },
            {
                image: require("@/assets/img/brand/exins.svg"),
                name: "Exins",
            },
            {
                image: require("@/assets/img/brand/miadria.svg"),
                name: "Miadria",
            },
            {
                image: require("@/assets/img/brand/sheworks.svg"),
                name: "SheWorks Africa",
            },
        ],
    }),
}
</script>