<template>
    <div class="text-center form-spacing">
        <p class="mb-5 form-title">Do you want them to be remote or on-site?</p>

        <div class="row g-3 mb-5">
            <div v-for="(jobType, index) in jobTypes" :key="index" class="col-6 d-flex">
                <div class="form-picker">
                    <input :id="jobType.value"
                        v-model="selectedJobType"
                        :value="jobType.value"
                        class="d-none"
                        name="serviceCategory"
                        type="radio"
                    />
                    <label :for="jobType.value" class="label">
                        <img :alt="jobType.name" :src="jobType.illustration" class="img-fluid"/>
                        <span class="mb-0 label">{{ jobType.label }}</span>
                    </label>
                </div>
            </div>
        </div>

        <button :disabled="!selectedJobType" class="btn btn-primary btn-lg" type="button" @click="proceed">
            <span class="btn__text-spacing">Next question </span> 
            <ion-icon name="arrow-forward"/>
        </button>
    </div>
</template>

<script>
    import {mapMutations, mapState} from "vuex";

    export default {
        name: "JobType",
        data() {
            return {
                selectedJobType: null,
                jobTypes: [
                    {
                        label: "Remote",
                        name: "Remote",
                        value: "remote",
                        illustration: "/illustrations/remote.svg",
                    },
                    {
                        label: "On-site",
                        name: "On-site",
                        value: "on-site",
                        illustration: "/illustrations/on-site.svg",
                    },
                ],

            };
        },
        mounted() {
            this.$emit("currentProgress",
                {
                    current: 3,
                    total: 4
                });
            this.selectedJobType = this.jobRequestValues.jobType;
        },
        methods: {
            proceed: function () {
                this.updateJobType(this.selectedJobType)
                this.$emit("proceed");
            },
            ...mapMutations('getStartedModule', {
                updateJobType: 'updateJobType'
            }),
        },
        computed: {
            ...mapState('getStartedModule', {
                jobRequestValues: `jobRequestValues`
            }),
        }
    };
</script>
<style lang="scss">
.label {
    font-size: 1.5rem;
}
</style>