<template>
    <div>
        <phone-number
                v-model="phoneNumber"
                :border-radius="8"
                :dark="true"
                :fetch-country="true"
                :no-example="true"
                class="mb-2"
                @update="emitPhoneNumber">
        </phone-number>
    </div>
</template>

<script>
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';

    export default {
        name: "PhoneNumberInput",
        components: {
            'phone-number': VuePhoneNumberInput
        },
        props: {
            defaultPhoneNumber: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                phoneNumber: '',
                payload: {},
                onlyCountries: [
                    "DZ", "AO", "BJ", "BW", "IO", "BF", "BI", "CM", "CV", "CF", "TD", "KM",
                    "CG", "CD", "DJ", "EG", "GQ", "ER", "ET", "GA", "GM", "GH", "GN",
                    "GW", "CI", "KE", "LS", "LR", "LY", "MG", "MW", "ML", "MR", "MU",
                    "MA", "MZ", "NA", "NE", "NG", "RE", "RW", "SH", "ST", "SN", "SC", "SL",
                    "SO", "ZA", "SS", "SD", "SZ", "TZ", "TG", "TN", "UG", "EH", "ZM", "ZW"
                ]
            }
        },
        created() {
            this.phoneNumber = this.defaultPhoneNumber
        },
        methods: {
            emitPhoneNumber: function ($event) {
                this.payload = $event;
                this.$emit('input', this.payload);
                this.$emit('change', this.payload);
            }
        },
    }
</script>

<style lang="scss">
@import "../../assets/scss/components/phone-number-input";
</style>
