<template>
    <div id="app">
        <router-view/>
        <v-dialog class="dialog" persistent="true"/>
    </div>
</template>

<script>
    import store from './store';

    export default {
        name: "App",
        store,
        created() {
            this.unsubscribe = store.subscribe((mutation, state) => {
                if (mutation.type === 'updateError' && state.error.message) {
                    this.showErrorDialog(state.error)
                } else if (mutation.type === 'updateAlert' && state.alert) {
                    this.$modal.show('dialog', {
                        title: state.alert.title,
                        text: state.alert.message,
                        buttons: state.alert.buttons
                    });
                }
            })
        },
        methods: {
            showErrorDialog(error) {
                this.$modal.show('dialog', {
                    title: error.title,
                    text: error.message
                })
            },
        },
        beforeDestroy() {
            this.unsubscribe();
        }
    };
</script>

<style lang="scss">
@import "assets/scss/styles";
</style>
