<template>
    <div>
        <div :class="hasContent" class="input-group">
            <select
                    :id="id"
                    class="form-select form-input custom-select select-input"
                    @change="emitValue"
            >
                <option v-if="label" disabled selected >Pick an option</option>
                <option
                        v-for="(option, index) in options"
                        :key="index"
                        :value="option.id"
                        :selected="option.id === defaultValue"
                        class="mb-3"
                >
                    {{ option.name }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FormSelect",
        props: {
            id: {
                type: String,
                required: true,
            },
            label: {
                type: String
            },
            options: {
                type: Array,
            },
            defaultValue: {
                type: Number,
                required: false
            },
        },
        data() {
            return {
                hasContent: false,
                value: null,
                placeholder: null,
            };
        },
        mounted() {
            if (this.defaultValue){
                this.value = this.defaultValue;
                this.$emit("input", this.id);
                this.$emit("change", this.id);
            }
        },
        methods: {
            updateClass: function () {
                if (this.value) {
                    this.hasContent = "has-content";
                } else {
                    this.hasContent = "";
                }
            },
            emitValue: function (event) {
                let selectedIndex = event.target.options.selectedIndex - (this.label? 1 : 0);
                if(selectedIndex > -1) {
                    this.updateClass();
                    this.id = this.options[selectedIndex].id
                    this.$emit("input", this.id);
                    this.$emit("change", this.id);
                }
            },
            onChange: function () {
                this.emitValue();
            },
        },
    };
</script>

<style lang="scss" scoped>
@import "../../assets/scss/components/form-input";
</style>
