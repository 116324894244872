<template>
    <div>
        <section class="homepage">
            <HomeHeader :links="links" linkClass="nav-link" />
        </section>

        <Hero :talents="talents" subText="text-light-grey" />

        <Brands />

        <TalentsPool :talents="talents" />

        <OurTalents />

        <WhyChooseUs />

        <OurOffer />

        <HowItWorks />

        <Testimonials />

        <Partners />

        <HomeFooter />
    </div>
</template>

<script>
import HomeHeader from "../components/layouts/HomeHeader";
import Hero from "../components/elements/Hero";
import OurTalents from "../components/elements/OurTalents";
import OurOffer from "../components/elements/OurOffer";
import WhyChooseUs from "../components/elements/WhyChooseUs";
import TalentsPool from "../components/elements/TalentsPool";
import HowItWorks from "../components/elements/HowItWorks";
import Brands from "../components/elements/Brands";
import Testimonials from "../components/elements/Testimonials";
import Partners from "../components/elements/Partners";
import HomeFooter from "../components/layouts/HomeFooter";
import { mapMutations } from "vuex";

export default {
    name: "Home",
    components: {
        HomeHeader,
        Hero,
        OurTalents,
        OurOffer,
        WhyChooseUs,
        TalentsPool,
        HowItWorks,
        Brands,
        Testimonials,
        Partners,
        HomeFooter
    },
    data: () => ({
        links: [
            {
                input: "Why choose us?",
                href: "#whyChooseUs",
            },
            {
                input: "Partners",
                href: "#partners",
            },
            {
                input: "Contact",
                href: "#contact",
            },
        ],
        talents: [
            {
                image: require("@/assets/img/hero/ruby.png"),
                name: "Ruby Adeniregun",
                company: "Merkle, USA",
                jobTitle: "Software Developer",
                heroImage: require("@/assets/img/dufunaTalents/ruby.png")
            },
            {
                image: require("@/assets/img/hero/opeyemi.png"),
                name: "Opeyemi Peter",
                company: "Merkle, USA",
                jobTitle: "Software Engineer | SFCC",
                heroImage: require("@/assets/img/dufunaTalents/opeyemi.png")
            },
            // {
            //     image: require("@/assets/img/hero/lara.png"),
            //     name: "Aliyon T",
            //     company: "Innovage Investment Ltd, Zambia",
            //     jobTitle: "UI/UX Designer",
            //     heroImage: require("@/assets/img/dufunaTalents/lara.png")
            // },
            {
                image: require("@/assets/img/hero/afolakemi.png"),
                name: "Afolakemi Ojo",
                company: "MMT, UK",
                jobTitle: "Product Manager",
                heroImage: require("@/assets/img/dufunaTalents/afolakemi.png")
            },
            {
                image: require("@/assets/img/hero/toluwalase.png"),
                name: "Toluwalase Akintoye",
                company: "Microsoft, Ireland",
                jobTitle: "Software Engineer",
                heroImage: require("@/assets/img/dufunaTalents/toluwalase.png")
            },
            {
                image: require("@/assets/img/hero/ndane.png"),
                name: "Ndane Ndazhaga",
                company: "Squadra-run, France",
                jobTitle: "Data Scientist",
                heroImage: require("@/assets/img/dufunaTalents/ndane.png")
            },
            {
                image: require("@/assets/img/hero/lara.png"),
                name: "Omolara Adisa",
                company: "BJSS, UK",
                jobTitle: "Platform Engineer",
                heroImage: require("@/assets/img/dufunaTalents/lara.png")
            },
            {
                image: require("@/assets/img/hero/isaac.png"),
                name: "Isaac Durotoye",
                company: "Ex. eTranzact, Nigeria",
                jobTitle: "Product Manager",
                heroImage: require("@/assets/img/dufunaTalents/issac.png")
            },
            {
                image: require("@/assets/img/hero/adaa.png"),
                name: "Adaa Mgbede",
                company: "Reponse, UAE",
                jobTitle: "Software Engineer",
                heroImage: require("@/assets/img/dufunaTalents/adaa.png")
            },
            {
                image: require("@/assets/img/hero/mary.png"),
                name: "Mary Okosun",
                company: "International Brewries, Nigeria",
                jobTitle: "Software Engineer",
                heroImage: require("@/assets/img/dufunaTalents/mary.png")
            },
            // commented out because of missing image
            // {
            //     image: require("@/assets/img/tolulopeF.png"),
            //     name: "Tolulope Fabunmi",
            //     company: "MTN, Nigeria",
            //     jobTitle: "Site Reliability Engineer",
            // },
            {
                image: require("@/assets/img/hero/joy.png"),
                name: "Joy Chukwukere",
                company: "MAX, Nigeria",
                jobTitle: "Fintech Product Manager",
                heroImage: require("@/assets/img/dufunaTalents/joy.png")
            }

        ],
    }),
    created() {
        this.setReturnURL(this.$route.path)
    },
    methods: {
        ...mapMutations({
            setReturnURL: 'setReturnURL'
        })
    }
};
</script>

<style lang="scss">
@import "../assets/scss/pages/homepage.scss";
</style>
