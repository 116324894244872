import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Home.vue"
import PartnersPage from "../views/PartnersPage"
import GetStarted from "../views/GetStarted"
import ProductCategory from "../components/get-started/ProductCategory";
import ServicePreference from "../components/get-started/ServicePreference";
import BusinessPurpose from "../components/get-started/BusinessPurpose";
import ClientTarget from "../components/get-started/ClientTarget";
import SimilarProducts from "../components/get-started/SimilarProducts";
import ProjectEstimation from "../components/get-started/ProjectEstimation";
import ContactInfo from "../components/get-started/ContactInfo";
import RequestSuccess from "../views/RequestSuccess";
import RequiredTalents from "@/components/get-started/RequiredTalents";
import JobType from "@/components/get-started/JobType";
import Error404 from "@/views/errors/Page404";
import PrivacyPolicy from "../views/PrivacyPolicy";
import SignUp from "../components/elements/SignUp";

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '/partners/:key',
		name: 'partners',
		component: PartnersPage
	},
	{
		path: '/sign-up',
		name: 'sign-up',
		component: SignUp
	},
	{
		path: '/get-started',
		name: 'GetStarted',
		component: GetStarted,
		children: [
			{path: 'service-preference', name: "ServicePreference", component: ServicePreference},
			{path: 'product-categories', name: "ProductCategory", component: ProductCategory},
			{path: 'business-purpose', name: "BusinessPurpose", component: BusinessPurpose},
			{path: 'client-target', name: "ClientTarget", component: ClientTarget},
			{path: 'similar-products', name: "SimilarProducts", component: SimilarProducts},
			{path: 'project-estimation', name: "ProjectEstimation", component: ProjectEstimation},

			{path: 'required-talents', name: "RequiredTalents", component: RequiredTalents},
			{path: 'job-type', name: "JobType", component: JobType},

			{path: 'contact-info', name: "ContactInfo", component: ContactInfo},
		]
	},
	{
		path: '/request-success',
		name: 'RequestSuccess',
		component: RequestSuccess
	},
	{
		path: '/privacy-policy',
		name: 'PrivacyPolicy',
		component: PrivacyPolicy
	},
	{
		path: '/*',
		name: 'Page404',
		component: Error404
	}
];

const router = new VueRouter({
	routes,
	mode: 'history'
});

export default router