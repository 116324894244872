<template>
    <div class="text-center form-spacing">
        <span class="form-title">Add links to any similar products</span>
        <p class="mb-5">(if any)</p>

        <div class="form-row mb-5">
            <div v-for="(link, index) in links"
                 :key="link.key" class="col-12 form-group form-picker d-flex justify-content-between">
                <form-input
                        id="url"
                        :default-value="link.url"
                        class="col-md-10"
                        label="URL link"
                        placeholder="Enter link"
                        type="url"
                        @input="onInput($event, index)">
                </form-input>
                <span class="col-md-2 p-2 pointer" @click="removeField(index)">
                <ion-icon name="close" size="large"/>
            </span>
            </div>
            <span class="d-flex align-items-center pointer text-blue"
                  @click="addLink('')">
            <ion-icon name="add-circle-outline" />Add more
        </span>
        </div>

        <button :class="[isValid ? 'enabled' : 'disabled']"
                class="btn btn-primary btn-lg"
                type="button"
                @click="proceed">
            <span class="btn__text-spacing">Next question </span> 
            <ion-icon name="arrow-forward"></ion-icon>
        </button>
    </div>
</template>

<script>
    import FormInput from "../forms/FormInput";
    import misc from "@/utils/misc";
    import uuid from 'uuid/v4'
    import {mapMutations, mapState} from "vuex";

    export default {
        name: "SimilarProducts",
        components: {
            "form-input": FormInput,
        },
        data() {
            return {
                links: [],
                urlAddress: null,
                isValid: true,
                showError: false,
                errorMsg: "",
            };
        },
        mounted() {
            this.$emit("currentProgress",
                    {
                        current: 5,
                        total: 7
                    });
            if (this.jobRequestValues.similarLinks)
                this.jobRequestValues.similarLinks.forEach(link => this.addLink(link));
            else
                this.addLink('');
        },
        methods: {
            validate: function () {
                if (this.links.isEmpty ||
                        (this.links.length === 1 && this.links[0].url === ''))
                    this.isValid = true
                else {
                    this.links.some(linkItem => {
                        this.isValid = linkItem.isValid
                        if (!linkItem.isValid)
                            return true
                    })
                }
            },
            proceed: function () {
                let similarLinks = this.links.map(linkItem => linkItem.url)
                this.updateSimilarLinks(similarLinks)
                this.$emit("proceed");
            },
            onInput: function (url, index) {
                let link = this.links[index]
                link.url = url.value
                link.isValid = url.isValid
                this.validate()
            },
            addLink(url) {
                this.links.push({
                    url: url,
                    isValid: misc.validateUrl(url),
                    key: uuid()
                })
                this.validate()
            },
            removeField(index) {
                this.$delete(this.links, index)
                this.validate()
            },
            ...mapMutations('getStartedModule', {
                updateSimilarLinks: 'updateSimilarLinks'
            })
        },
        computed: {
            ...mapState('getStartedModule', {
                jobRequestValues: `jobRequestValues`
            })
        }
    };
</script>
