<template>
    <div>
        <section class="homepage">
            <HomeHeader :links="links" linkClass="nav-link" />
        </section>

        <div class="container my-5 text-soft-black">
            <h1>Dufuna Technologies Limited Privacy Policy</h1>
            <p>Last revised on: 21st, June 2020</p>

            <div>
                <span class="privacy-policy__title">1. Scope of this policy</span>
                <p>
                    This privacy policy (the "Policy" or the "Privacy Policy") describes how Dufuna Technologies Limited
                    collects, uses, consults or otherwise processes an individual's Personal Data. For the purposes of
                    this policy, "Dufuna" refers to Dufuna Technologies Limited, a limited liability company
                    incorporated under the laws of the Federal Republic of Nigeria, having its registered office at The
                    Third Floor, 109 Awolowo Road, Ikoyi, Lagos, Nigeria <a class="text-blue" href="mailto:info@dufuna.org">
                    info@dufuna.org</a>. Unless otherwise stated, Dufuna will process your personal data as a Data
                    Controller.
                </p>
                <div>
                    <p>
                        We are committed to protecting the privacy of our users and customers. This Privacy Policy is
                        especially directed at:
                    </p>
                    <ul>
                        <li>Visitors and subscribers of the Dufuna Mobile Application (‘App’);</li>
                        <li>Natural persons contacting us through the contact or other forms available on the website;</li>
                        <li>Users signing in to use our services;</li>
                    </ul> 
                </div>
                <p>
                    This Privacy Policy is intended to inform you how we gather, define, and use information that could
                    identify you, such as your name, email address, photograph address, other contact details, online
                    identifiers or other information that you provide to us when using our websites or when relying on
                    our services. Please take a moment to read this Privacy Policy carefully. This policy also includes
                    a description of your data protection rights, including a right to object to some of the processing
                    activities we carry out.
                </p>
            </div>

            <div>
                <span class="privacy-policy__title">2. Contacting us</span>
                <p>
                    If you have general questions about your account please send us an email at <a class="text-blue" 
                    href="mailto:info@dufuna.org">info@dufuna.org</a>. Please note that if you contact us to assist you,
                    for your safety and ours we may need to authenticate your identity before fulfilling your request.
                </p>
            </div>

            <div>
                <span class="privacy-policy__title">3. Collection of Information</span>
                <p>
                    Dufuna exists to train individuals in information and communications technology (ICT) and workplace
                    productivity skills, provide recruitment services for clients seeking to hire talents, and provide
                    advisory services for clients seeking to build a product.  As part of our effort to provide these
                    trainings, we receive and store information about you such as:
                </p>

                <span class="privacy-policy__sub-title">a) Personal Information</span>
                <p>
                    Certain features and services may require registration. Users may register ("sign-up") on-app or via
                    a third party (for example "via Google"). Upon signing-up, an account is created for the user
                    ("Account"). Users who sign-up on-app are required to confirm their registration by clicking on an
                    activation link which is sent to them via email. Users who sign-up through a third party are
                    automatically activated. Users are required to provide part or all of the following information
                    below, depending on the features or services they wish to access (some of which may be automatically
                    retrieved directly from the third party):
                </p>
                <ul>
                    <li>First and last name</li>
                    <li>Date of Birth</li>
                    <li>Job title, industry, and level</li>
                    <li>Phone Number</li>
                    <li>Photograph (including camera permission)</li>
                    <li>Social Media Permission</li>
                    <li>Company Logo</li>
                    <li>Company Name</li>
                </ul>
                <p>
                    Once you complete activation, you will be able to create your profile ("Profile"). You may be
                    required to provide us with additional information and consent to our terms.
                </p>
                <p>
                    Photograph Capturing During Tests <br>
                    This App requires access to the front-facing camera on your device and once you grant this App access to your 
                    Camera, it will be possible to capture and store photographs via the camera on your device. Picture-taking by 
                    the App is automatic and will only be restricted to periods when the User is taking a test on the App. The 
                    purpose of this feature is to verify and ascertain that the User registered to that Profile is the one taking 
                    the test. Pictures taken by the App during the tests are securely stored and are only accessed by authorized 
                    staff and administrators in the event of the need to verify the identity of the test taker. Pictures taken shall 
                    not be used for any purpose other than stated in this policy. You are advised to be dressed appropriately and be 
                    positioned properly during all tests as pictures will be taken intermittently and without warning. If you 
                    believe that an inappropriate picture has been taken of you, you may request erasure from us by sending an email 
                    to <a class="text-blue" href="mailto:info@dufuna.org">info@dufuna.org</a>. Although we do not offer an 
                    guarantee, Dufuna will do its best to pair you up with a staff or administrator of the same sex to work with you 
                    on the erasure request.
                </p>

                <span class="privacy-policy__sub-title">b) Other Information</span>
                <p>
                    You may have the opportunity to interact with other Users on the App; either directly or in a Forum. We cannot 
                    and do not guarantee the veracity of information communicated by other Users, nor do we guarantee that the 
                    information contributed by you would not be viewed by persons not on the App.
                </p>
                <p>
                    Non-personal information <br>
                    We automatically track and collect certain non-personal information such as App navigation data, Internet 
                    Protocol ("IP") addresses, timestamps and URLs. We use this information to do internal research on our users’ 
                    demographics, interest, and behavior to better understand, protect and serve you and our Dufuna community.
                </p>
                <p>
                    Correspondence information <br>
                    If you send us personal correspondence, such as emails or letters, or if other users or third parties send us 
                    correspondence about your activities or postings on our Website, we may store such information on our servers.
                </p>
                <p>
                    Payment Information <br>
                    All payments are processed by our third party payment processor and as such, are largely governed by their terms
                    of service and policies. We allow our third party payment processors to collect information for the purpose of
                    collecting fees from users on the Website. We do not have access to the payment information that you provide to
                    our third party payment vendor. For more information regarding what payment information our third party payment
                    vendor collects and how it uses this information, please see their privacy policy.

                </p>
                <p>
                    To protect you against fraud, unauthorized transactions (such as money laundering), claims and other 
                    liabilities, we only collect an identification number and order details which enable us to match each user to 
                    the payments he makes. We do not collect credit card information.
                </p>
            </div>

            <div>
                <span class="privacy-policy__title">4. Use of Information </span>
                <p>
                    We use your information to provide, analyse, administer, enhance and personalize our services and marketing 
                    efforts, to process your registration, your orders and your payments and to communicate with you on these and 
                    other topics. For example, we use information to : 
                </p>
                <ul>
                    <li>
                        We use your Information in order to resolve disputes; troubleshoot problems; help promote safe matching; 
                        collect fees owed; measure user interest in Services; inform you about offers, products, services, and 
                        updates; customize your experience; detect and protect us against error, fraud and other criminal activity; 
                        enforce our terms of service; and as otherwise described to you at the time of collection.
                    </li>
                    <li>
                        We use your e-mail to send you system e-mails about the functionality of our platform that you cannot opt 
                        out from and you will have to receive if you want to use our App. If you want to stop receiving our system 
                        e-mails, you will have to de-activate your Account by contacting <a class="text-blue" 
                        href="mailto:info@dufuna.org">info@dufuna.org</a>
                    </li>
                    <li>
                        We may use the e-mail associated with your Account to send you newsletters and promotions in conjunction 
                        with your use of our Services. If you do not wish to receive newsletters you may unsubscribe by clicking the 
                        unsubscription link in the newsletter e-mail. We may also send you newsletters which include third party’s 
                        offers. If you do not wish to receive these third party offers, you may unsubscribe by clicking the 
                        unsubscription link in the newsletter e-mail. 
                    </li>
                    <li>
                        We use navigation data to operate and improve the App. We may also use navigation data alone or in 
                        combination with your other Information to provide aggregated information about the Dufuna experience.
                    </li>
                    <li>
                        We collect the IP Addresses to track when you use our App. We use IP Addresses to monitor the regions from 
                        which you navigate our App and sign-up to use our Services. Your IP-address is also registered for 
                        statistical purposes and to better our advertising. 
                    </li>
                    <li>
                        We do not share your full name, photograph captured, e-mail, phone number and job title with third parties 
                        for their marketing purposes as a matter of course. However, if we decide to share such information, you 
                        shall be notified and shall have the option to opt-out from providing us with your personal information 
                        above for this purpose.
                    </li>
                </ul>
                <span class="privacy-policy__sub-title">Third Parties</span>
                <ul>
                    <li>
                        We partner with third-parties to either display advertising, process payment or do any such thing as we may 
                        deem necessary on our App. Generally, these third-parties do not have access to any information collected by 
                        us. They may, however, anonymously track your internet usage by various means, for example, through the use 
                        of cookies. 
                    </li>
                    <li>
                        We make reasonable efforts to make sure that other users who use our Services, will use the information 
                        available to them solely for learning purposes. However, we cannot control their use of that information 
                        outside this scope and we are not responsible for such unauthorized uses. 
                    </li>
                    <li>
                        Dufuna reserves its right to share your Information with a third party in case of sale merger, control 
                        changes, reorganization or liquidation of the Company. All actions will naturally be completed in compliance 
                        with applicable laws of personal data and privacy.
                    </li>
                </ul>
                <p>
                    Our legal basis for collecting and using the personal information described in this Privacy Policy will depend 
                    on the personal information concerned and the specific context in which we collect and use it. We will normally 
                    collect personal information from you where we need the personal information to perform a contract with you (for 
                    example to provide our services to you), where the processing is in our legitimate interests and not overridden 
                    by your data protection interests or fundamental rights and freedoms (for example our direct marketing 
                    activities in accordance with your preferences), or where we have your consent to do so (for example, for you to 
                    participate in specific surveys and focus groups).
                </p>
                <p>
                    In some cases, we may also have a legal obligation to collect personal information from you or may otherwise 
                    need the personal information to protect your vital interests or those of another person (for example to prevent 
                    payment fraud or confirm your identity).
                </p>
                <p>
                    For questions about our use of your personal information not adequately addressed here, please contact us at 
                    <a class="text-blue" href="mailto:info@dufuna.org">info@dufuna.org</a>
                </p>
            </div>

            <div>
                <span class="privacy-policy__title">5. Protection of Information</span>
                <p>
                    We use reasonable administrative, logical, physical and managerial measures to safeguard your personal 
                    information against loss, theft and unauthorised access, use and modification. These measures are designed to 
                    provide a level of security appropriate to the risks of processing your personal information. Indicatively :
                </p>
                <ul>
                    <li>
                        All our employees, independent contractors and agents have executed non-disclosure agreements, which provide 
                        explicit confidentiality protections. Any employee, independent contractor or agent who violates such 
                        privacy and/or security policies is subject to possible termination and civil and/or criminal prosecution. 
                    </li>
                    <li>
                        We do not make any of your information available to third parties for their marketing purposes. Dufuna’s 
                        software runs on individual servers and no data given or collected is shared with other social media 
                        platforms. If however, we share your Information with third parties, we will notify you. Please note that no 
                        transmission over the internet can guarantee confidentiality and non-disclosure, and as such, you transmit 
                        at your own risk
                    </li>
                    <li>
                        We use robust security measures to protect data from unauthorized access, maintain data accuracy, and help 
                        ensure the appropriate use of data. When the Services are accessed using the internet, Secure Socket Layer 
                        (SSL) technology protects your Information, using both server authentication and data encryption. These 
                        technologies help ensure that your Information is safe, secure, and only available to you and to whom you 
                        have granted access.
                    </li>
                    <li>
                        We do not tolerate spam. To report Dufuna-related spam, please contact us at <a class="text-blue" 
                        href="mailto:info@dufuna.org">info@dufuna.org</a>. You may not use our communication tools to send spam or 
                        otherwise send content that would violate our terms of service. We may check for spam, viruses, phishing 
                        attacks and other malicious activity or illegal or prohibited content of the Website, but we will not 
                        permanently store messages sent through these tools.
                    </li>
                    <li>
                        Dufuna does its utmost to secure communications and data storage in order to protect confidentiality of your 
                        information against loss and interception by third parties. However, it is important to know that there is 
                        no zero-risk against loss or interception by others of your Information. You are responsible for maintaining 
                        the security and confidentiality of your Account’s username and password.
                    </li>
                </ul>
            </div>

            <div>
                <span class="privacy-policy__title">6. Storage of Information</span>
                <p>
                    We save your Information in our database in order to improve our platform and user experience. If you wish that 
                    your Information be permanently deleted from our database when you stop using our Services, please notify us at 
                    <a class="text-blue" href="mailto:info@dufuna.org">info@dufuna.org</a>.
                </p>
            </div>

            <div>
                <span class="privacy-policy__title">7. Information and Rights</span>
                <p>
                    Once you have provided your Personal Data, you have several rights, which you exercise subject to statutory 
                    exceptions. Some of them are:
                </p>
                <ul>
                    <li>
                        <span class="fw-bold">Right to access and rectify your data</span><br>
                        You have the right to access, review, and rectify your Personal Data. You may be entitled to ask us for a 
                        copy of your information, to review or correct it if you wish to rectify any information like your name, 
                        email address, passwords and/or any other preferences, you can easily do so by logging into your account on 
                        our platform or by contacting us at <a class="text-blue" href="mailto:info@dufuna.org">info@dufuna.org</a>.
                    </li>
                    <li>
                        <span class="fw-bold">Right to erasure</span><br>
                        You have the right to erasure of your Personal Data processed by us as described in this Privacy
                        Policy in case it is no longer needed for the purposes for which the Personal Data was initially
                        collected or processed or in the event you have withdrawn your consent or objected to processing
                        as described in this privacy Policy and no other legal ground for processing applies. Should you
                        wish to have your Personal Data erased, please send us a request at <a class="text-blue" href="mailto:info@dufuna.org">
                        info@dufuna.org</a>.
                    </li>
                    <li>
                        <span class="fw-bold">Right to restriction of processing</span><br>
                        Under certain circumstances, you may ask us to restrict the processing of your Personal Data.
                        This is for example the case when you contest the accuracy of your Personal Data. In such event,
                        we will restrict the processing until we can verify the accuracy of your data.Should you wish
                        to have your Personal Data restricted, please send us a request at <a class="text-blue" href="mailto:info@dufuna.org">
                        info@dufuna.org</a>.
                    </li>
                </ul>
            </div>

            <div>
                <span class="privacy-policy__title">8. Other Platforms</span>
                <p>
                    This App may contain links to other platforms operated by third parties whose privacy policies and
                    terms of service may differ from ours. We recommend that you read those documents carefully.
                </p>
            </div>

            <div>
                <span class="privacy-policy__title">9. Changes to this Privacy Policy</span>
                <p>
                    We reserve the right to modify and update this Privacy Policy from time to time. We will bring these
                    changes to your attention if they are major or fundamental changes and impact your data protection rights.
                </p>
            </div>

            <div>
                <span class="privacy-policy__title">10. Data Retention</span>
                <p>
                    We retain your Personal Data for as long as is required to fulfil the activities set out in this
                    Privacy Policy, for as long as otherwise communicated to you or for as long as is permitted by
                    applicable law.
                </p>
            </div>

            <div>
                <span class="privacy-policy__title">11. Applicable Law</span>
                <p>
                    The validity and interpretation of this Privacy Policy shall be governed by the laws of the
                    Federation of Nigeria.
                </p>
            </div>
        </div>

        <HomeFooter />
    </div>
</template>

<script>
import HomeHeader from "../components/layouts/HomeHeader";
import HomeFooter from "../components/layouts/HomeFooter";

export default {
    name: "PrivacyPolicy",
    components: {
        HomeHeader,
        HomeFooter
    },
    data() {
        return {
            links: [
                {
                    input: "Why choose us?",
                    href: "#whyChooseUs",
                },
                {
                    input: "Partners",
                    href: "#partners",
                },
                {
                    input: "Contact",
                    href: "#contact",
                },
            ],
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/privacy-policy.scss";
</style>