<template>
    <section class="container">
        <nav class="navbar navbar-expand-lg pt-5">
            <div class="container">
                <router-link class="navbar-brand" :to="returnURL">
                    <img v-if="partner"
                         alt="Dufuna marketplace logo"
                         class="header__img"
                         :src="partner.logo_url"
                    />
                    <img v-else
                         class="header__img"
                         alt="Dufuna marketplace logo"
                         src="@/assets/img/brand/group.svg"
                    />
                </router-link>
            </div>
        </nav>
        <section class="container py-5">
            <div class="row hero section-spacing">
                <div class="col-md-6 col-sm py-5">
                    <div class="build">
                        <p class="hero__heading">Request submitted successfully</p>
                        <p class="display__header-text">
                            Thank you for taking time out to fill the questionnaire. Our
                            representative will reach out to you via email within
                            <span class="text-black font-weight-bold">3 working days</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-sm">
                    <div class="container hero-banner">
                        <img
                            alt="Hero image of dufuna talent page"
                            loading="lazy"
                            srcset="
                                 ../assets/img/brand/request.png    1x,
                                 ../assets/img/brand/request@2x.png 2x,
                                 ../assets/img/brand/request@3x.png 3x"
                        />
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
    import {mapState} from 'vuex';
    
    export default {
        name: "RequestSuccess",
        computed: {
            ...mapState('partnersModule', {
                partner: 'partner'
            }),
            ...mapState(['returnURL'])
        }
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/components/home-header";
</style>
