<template>
    <div class="text-center">
        <span class="form-title">How soon do you want to start?</span>

        <div class="row g-3 my-5">
            <div v-for="(estimate, index) in estimates" :key="index" class="col-md-6">
                <div class="form-picker">
                    <input
                        :id="estimate.value"
                        v-model="selectedEstimate"
                        :value="estimate.value"
                        class="d-none"
                        name="projectEstimate"
                        type="radio"
                    />
                    <label :for="estimate.value">
                        <span class="mb-0 label">{{ estimate.label }}</span>
                    </label>
                </div>
            </div>
        </div>

        <button :disabled="!selectedEstimate" class="btn btn-primary btn-lg" type="button" @click="proceed">
            <span class="btn__text-spacing">Next question</span> 
            <ion-icon name="arrow-forward" />
        </button>
    </div>
</template>

<script>
    import {mapMutations, mapState} from "vuex";

    export default {
        name: "ProjectEstimation",
        data() {
            return {
                selectedEstimate: null,
                estimates: [
                    {
                        label: "1 Week",
                        name: "One Week",
                        value: "one-week",
                    },
                    {
                        label: "2 Weeks",
                        name: "Two Week",
                        value: "two-weeks",
                    },
                    {
                        label: "1 Month",
                        name: "One Month",
                        value: "one-month",
                    },
                    {
                        label: "Within 3 months",
                        name: "Within Three months",
                        value: "within-three-months",
                    },
                ],
            };
        },
        mounted() {
            this.$emit("currentProgress",
                    {
                        current: 6,
                        total: 7
                    });
            this.selectedEstimate = this.jobRequestValues.timeline;
        },
        methods: {
            proceed: function () {
                this.updateTimeline(this.selectedEstimate)
                this.$emit("proceed");
            },
            ...mapMutations('getStartedModule', {
                updateTimeline: 'updateTimeline'
            })
        },
        computed: {
            ...mapState('getStartedModule', {
                jobRequestValues: `jobRequestValues`
            })
        }
    };
</script>
