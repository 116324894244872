<template>
    <div class="section-spacing">
        <div class="container mx-auto">
            <div class="text-center">
                <p class="display__title pb-3">What We Can Do For You</p>
                <p class="display__header-text"> We offer the best services Cross-funtionally.</p>
            </div>

            <div class="row">
                <div v-for="(offer, index) in offers" :key="index" class="col-lg-4 col-md-6" >
                    <div class="card-body">
                        <img :src="offer.illustration" alt="illustration">
                        <h5 class="card__header-text pt-3 pb-2">{{ offer.title }}</h5>
                        <p class="card__sub-text">{{ offer.text }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OurOffer",
    data: () => ({
        offers: [
            {
                title: "Build your product",
                text: "We bring your idea to life following design and coding best practices. From a first MVP to a fully scalable solution - depending on your requirements.",
                illustration: require("@/assets/img/icons/Group_84.svg"),
            },
            {
                title: "Staff your team",
                text: "We're on your side. Allow us to pick talent perfectly matching your technology, industry and company culture.",
                illustration: require("@/assets/img/icons/Group_83.svg"),
            },
            {
                title: "Diversify your hiring pipeline",
                text: "We provide talents with different and diverse background for your hiring needs.",
                illustration: require("@/assets/img/icons/Group_82.png"),
            },
        ],
    }),
};
</script>