import index from './index'

const resetGetStartedStates = state => {
	Object.assign(state, index.getDefaultGetStartedStates());
};

const updateClientRequestType = (state, requestType) => {
	state.jobRequestValues.requestType = requestType
};

const updateProductType = (state, productType) => {
	state.jobRequestValues.productType = productType
};

const updateProblemStatement = (state, problemStatement) => {
	state.jobRequestValues.problemStatement = problemStatement
};

const updateCustomerBase = (state, customerBase) => {
	state.jobRequestValues.customerBase = customerBase
};

const updateSimilarLinks = (state, similarLinks) => {
	state.jobRequestValues.similarLinks = similarLinks
};

const updateTimeline = (state, timeline) => {
	state.jobRequestValues.timeline = timeline
};

const updateRequiredTalents = (state, requiredTalents) => {
	state.jobRequestValues.requiredTalents = requiredTalents
};

const updateJobType = (state, jobType) => {
	state.jobRequestValues.jobType = jobType
};

const setClientObject = (state, clientObject) => {
	state.clientObject = clientObject
};

const setJobRequestValues = (state, jobRequestValues) => {
	state.jobRequestValues = jobRequestValues
};

const updateIsRegistered = (state, isRegistered) => {
	state.isRegistered = isRegistered
};

const jobRequestMutation = (state, payload) => {
	state.jobRequestState = payload;
};


export default {
	resetGetStartedStates,
	updateClientRequestType,
	updateProductType,
	updateProblemStatement,
	updateCustomerBase,
	updateSimilarLinks,
	updateTimeline,
	updateRequiredTalents,
	updateJobType,
	setClientObject,
	setJobRequestValues,
	updateIsRegistered,
	jobRequestMutation
}