<template>
    <div id="howItWorks" class="how-it-works section-spacing">
        <div class="container">
            <div class="col-md-4 mb-5">
                <p class="display__title pb-4">
                    Our service to your goal is
                    <span class="text-blue-gradient">top priority </span> for us.
                </p>

                <router-link
                    class="btn btn-primary btn-brand-primary mt-1"
                    to="/sign-up"
                >
                Get started
                    <span class="btn__icons"><i class="bi bi-arrow-up-right" /></span>
                </router-link>
            </div>

        <div class="row pt-2">
            <div v-for="(service, index) in services" :key="index" class="col-lg-4 col-md-6">
            <div class="card-body px-0">
                <img :src="service.image" :alt="service.name" class="mb-3">
                <h5 class="card__header-text pt-2 pb-2">{{ service.title }}</h5>
                <p class="card__sub-text">{{ service.desc }}</p>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HowItWorks",
    data: () => ({
        services: [
            {
                title: "Tell us what you need",
                desc: "Answer a few questions about your product, team dynamics and expectations.",
                image: require("@/assets/img/icons/01.svg"), 
                name: '01',
            },
            {
                title: "Hire",
                desc: "We can assemble a team for you or you can select from a shortlist.",
                image: require("@/assets/img/icons/02.svg"), 
                name: '02',
            },
            {
                title: "Project Kickoff",
                desc: "Onboard your team and start building.",
                image: require("@/assets/img/icons/03.svg"), 
                name: '03',
            },
        ],
    }),
};
</script>

<style lang="scss">
@import "@/assets/scss/components/how-it-works.scss";
</style>
