<template>
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-3 ">
                <p class="text-medium-gray text-uppercase text-left mb-5">
                    Trusted by leading brands and startups
                </p>
            </div>

            <div class="col-12 col-lg-9">
                <div class="row">
                    <BrandLogos 
                        parentClass="brand-logos" 
                        brandClass="col-6 col-md-3 pb-3 mb-3 d-flex justify-content-center" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BrandLogos from "../elements/BrandLogos"

export default {
    name: "Brands",
    components: {
        BrandLogos
    }
};
</script>
