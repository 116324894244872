<template>
    <div class="text-center">
        <p class="mb-5 form-info">
            Kindly answer a few questions to make us understand your needs better. This should take less than 5 minutes.
        </p>

        <p class="mb-5 form-title">What service do you require?</p>

        <div class="row g-3 mb-5">
            <div v-for="(category, index) in categories" :key="index" class="col-6 d-flex">
                <div class="form-picker">
                    <input :id="category.value"
                           v-model="selectedRequestType"
                           :value="category.value"
                           class="d-none"
                           name="serviceCategory"
                           type="radio"
                           @change="emitValue"
                    />
                    <label :for="category.value">
                        <img :alt="category.name" :src="category.illustration" class="img-fluid"/>
                        <span class="mb-0 label">{{ category.label }}</span>
                    </label>
                </div>
            </div>
        </div>

        <button :disabled="!selectedRequestType" class="btn btn-primary btn-lg" type="button" @click="submit">
            <span class="btn__text-spacing">Next question</span> 
            <ion-icon name="arrow-forward" />
        </button>
    </div>
</template>

<script>
    import {mapActions, mapMutations, mapState} from "vuex";
    import store from "@/store";

    export default {
        name: "ServicePreference",
        data() {
            return {
                selectedRequestType: null,
                categories: [{
                    label: "Build a product",
                    name: "Build a product",
                    value: "build-a-product",
                    illustration: "/illustrations/group-2.svg",
                },
                    {
                        label: "Hire talents",
                        name: "hire talents",
                        value: "hire-talents",
                        illustration: "/illustrations/group-1.svg",
                    },
                ],
            };
        },
        mounted() {
            this.unsubscribe = store.subscribe(mutation => {
                if (mutation.type === 'setCountriesAndStates'
                        && this.countriesAndStates.length) {
                    if (this.selectedRequestType === 'build-a-product'
                            || (this.technicalSkills.length && this.jobRoles.length))
                        this.proceed()
                    else if (!this.technicalSkills.length)
                        this.getTechnicalSkills()
                    else if (!this.jobRoles.length)
                        this.getJobRoles()
                } else if (mutation.type === 'setTechnicalSkills' && this.technicalSkills.length) {
                    if (!this.jobRoles.length) {
                        this.getJobRoles()
                    } else {
                        this.proceed()
                    }
                } else if (mutation.type === 'setJobRoles' && this.jobRoles.length) {
                    this.proceed()
                }
            })

            this.$emit("currentProgress",{
                current: 1,
                total: 7
            });
            this.selectedRequestType = this.jobRequestValues.requestType;
            this.emitValue()
        },
        methods: {
            emitValue: function () {
                const total = this.selectedRequestType === 'build-a-product' ? 7 : 4
                this.$emit("currentProgress",{
                    current: 1,
                    total: total
                });
                this.$emit("change", this.selectedRequestType);
            },
            submit: function () {
                if (this.countriesAndStates.length) {
                    if (this.selectedRequestType === 'build-a-product'
                            || (this.technicalSkills.length && this.jobRoles.length))
                        this.proceed()
                    else if (!this.technicalSkills.length)
                        this.getTechnicalSkills()
                    else if (!this.jobRoles.length)
                        this.getJobRoles()
                } else this.getCountriesAndStates();
            },
            proceed: function () {
                this.updateClientRequestType(this.selectedRequestType)
                this.$emit("proceed");
            },
            ...mapMutations('getStartedModule', {
                updateClientRequestType: 'updateClientRequestType'
            }),
            ...mapActions({
                getCountriesAndStates: 'getCountriesAndStates',
                getTechnicalSkills: 'getTechnicalSkills',
                getJobRoles: 'getJobRoles'
            })
        },
        computed: {
            ...mapState('getStartedModule', {
                jobRequestValues: `jobRequestValues`
            }),
            ...mapState({
                countriesAndStates: `countriesAndStates`,
                technicalSkills: `technicalSkills`,
                jobRoles: `jobRoles`
            }),
        },
        beforeDestroy() {
            this.unsubscribe();
        }
    };
</script>
