import {RepositoryFactory} from "@/repo/RepositoryFactory";
import {utils} from "@/utils";
import NProgress from "nprogress";
import router from '@/router';

const clientRepository = RepositoryFactory.get('client');
const constants = utils.constants;
const responseCodes = constants.responseCodes;

const createClientAndJobRequest = async (context, body) => {
	NProgress.start();
	const email = body.email;
	const contact_name = body.contact_name;
	const business_name =body.business_name;
	const phone_number = body.phone_number;
	const additional_information = body.additional_information;
	const industry_category_id = body.industry_category_id;
	const country_id = body.country_id;
	const request_type = body.request_type;

	await clientRepository.createClientAndJobRequest(
		email,
		contact_name,
		business_name,
		phone_number,
		additional_information,
		industry_category_id,
		country_id,
		request_type,
		context.rootState.token
	).then(response => {
			const responseData = response.data;
			const responseStatus = response.status;
			if (responseStatus === responseCodes.successCode
				&& responseData.status === constants.success) {
				router.push({ path: `/request-success`})
				context.commit('jobRequestMutation', true);
			} else if (responseStatus === responseCodes.invalidTokenCode) {
				utils.misc.onAuthTokenExpired(context, 'getStartedModule/createClientAndJobRequest', body);
			} else if (!responseData.message) {
				utils.misc.showInternalError(context);
			} else {
				utils.misc.showGeneralError(context, responseData.message);
			}
			NProgress.done();
		}).catch(() => {
			utils.misc.catchNetworkError(context);
			NProgress.done();
		})
};

export default {
	createClientAndJobRequest
}