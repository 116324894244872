import {RepositoryFactory} from '@/repo/RepositoryFactory';
import NProgress from 'nprogress'
import {utils} from '@/utils';

const dataRepository = RepositoryFactory.get('data');
const authRepository = RepositoryFactory.get('auth');
const constants = utils.constants;
const responseCodes = constants.responseCodes;

const getCountries = async (context) => {
	await dataRepository.getCountries(context.state.token)
		.then(response => {
			const responseData = response.data;
			const responseStatus = response.status;
			if (responseStatus === responseCodes.successCode
				&& responseData.status === constants.success) {
				context.commit('countriesMutation', responseData.data);
			} else if (responseStatus === responseCodes.invalidTokenCode) {
				utils.misc.onAuthTokenExpired(context, 'getCountriesAndStates', null);
			} else if (!responseData.message)
				utils.misc.showInternalError(context);
			else {
				utils.misc.showGeneralError(context, responseData.message);
			}
			NProgress.done();
		}).catch(() => {
			utils.misc.catchNetworkError(context);
			NProgress.done();
		})
};

const getIndustryCategory = async (context) => {
	await dataRepository.getIndustryCategory(context.state.token)
		.then(response => {
			const responseData = response.data;
			const responseStatus = response.status;
			if (responseStatus === responseCodes.successCode
				&& responseData.status === constants.success) {
				context.commit('industryCategoryMutation', responseData.data);
			} else if (responseStatus === responseCodes.invalidTokenCode) {
				utils.misc.onAuthTokenExpired(context, 'industryCategoryState');
			} else if (!responseData.message) {
				utils.misc.showInternalError(context);
			} else {
				utils.misc.showGeneralError(context, responseData.message);
			}
			NProgress.done();
		}).catch(() => {
			utils.misc.catchNetworkError(context);
			NProgress.done();
		})
};

const getJobRoles = async (context) => {
	NProgress.start();
	context.commit('setJobRoles', []);
	await dataRepository.getJobRoles(context.state.token)
		.then(response => {
			const responseData = response.data;
			const responseStatus = response.status;
			if (responseStatus === responseCodes.successCode
				&& responseData.status === constants.success) {
				context.commit('setJobRoles', responseData.data);
			} else if (responseStatus === responseCodes.invalidTokenCode) {
				utils.misc.onAuthTokenExpired(context, 'getJobRoles', null);
			} else if (!responseData.message) {
				utils.misc.showInternalError(context);
			} else {
				utils.misc.showGeneralError(context, responseData.message);
			}
			NProgress.done();
		}).catch(() => {
			utils.misc.catchNetworkError(context);
			NProgress.done();
		})
};

const getTechnicalSkills = async (context) => {
	NProgress.start();
	context.commit('setTechnicalSkills', []);
	await dataRepository.getTechnicalSkills(context.state.token)
		.then(response => {
			const responseData = response.data;
			const responseStatus = response.status;
			if (responseStatus === responseCodes.successCode
				&& responseData.status === constants.success) {
				context.commit('setTechnicalSkills', responseData.data);
			} else if (responseStatus === responseCodes.invalidTokenCode) {
				utils.misc.onAuthTokenExpired(context, 'getTechnicalSkills', null);
			} else if (!responseData.message) {
				utils.misc.showInternalError(context);
			} else {
				utils.misc.showGeneralError(context, responseData.message);
			}
			NProgress.done();
		}).catch(() => {
			utils.misc.catchNetworkError(context);
			NProgress.done();
		})
};

const getAuthToken = async (context) => {
	authRepository.getAccessToken().then((response) => {
		const responseData = response.data;
		const responseStatus = response.status;
		if (responseStatus === responseCodes.successCode
			&& responseData.status === constants.success) {
			let token = responseData.data.access_token;
			context.commit('updateTokenMutation', token);
		} else if (!responseData.message)
			utils.misc.showInternalError(context);
		else
			utils.misc.showGeneralError(context, responseData.message);
		NProgress.done();
	}).catch(() => {
		utils.misc.catchNetworkError(context);
		NProgress.done();
	})
};


export default {
	getCountries,
	getIndustryCategory,
	getAuthToken,
	getJobRoles,
	getTechnicalSkills
}