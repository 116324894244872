<template>
    <div class="form-progress">
        <div class="form-progress__bar">
            <div ref="level" class="form-progress__level"></div>
        </div>
        <div class="form-progress__stats">{{ current }}/{{ total }}</div>
    </div>
</template>

<script>
    export default {
        name: "FormProgressBar",
        props: {
            total: Number,
            current: Number,
        },
        methods: {
            getPercentageCompletion: function () {
                return (this.current / this.total) * 100;
            },
            updateLevelWidth: function () {
                this.$refs.level.style.width = this.getPercentageCompletion() + "%";
            },
        },
        mounted() {
            this.updateLevelWidth();
        },
        updated() {
            if (this.getPercentageCompletion() <= 100) {
                this.updateLevelWidth();
            }
        },
    };
</script>

<style lang="scss" scoped>
@import "src/assets/scss/components/form-progress-bar";
</style>
