<template>
    <div class="text-center form-spacing">
        <span class="form-title">Describe your target customer base</span>
        <p class="mb-5">in a single sentence</p>

        <div class="form-row mb-5">
            <div class="form-group form-picker d-flex justify-content-center">
                <input
                        id="targetId"
                        v-model="customerTarget"
                        class="form-control input"
                        type="text"
                />
            </div>
        </div>

        <button
                :disabled="!customerTarget"
                class="btn btn-primary btn-lg"
                type="button"
                @click="proceed"
        >
            <span class="btn__text-spacing">Next question </span> 
            <ion-icon name="arrow-forward" />
        </button>
    </div>
</template>

<script>
    import {mapMutations, mapState} from "vuex";

    export default {
        name: "ClientTarget",
        data() {
            return {
                customerTarget: "",
            };
        },
        mounted() {
            this.$emit("currentProgress",
                    {
                        current: 4,
                        total: 7
                    });
            this.customerTarget = this.jobRequestValues.customerBase || this.customerTarget;
        },
        methods: {
            proceed: function () {
                this.updateCustomerBase(this.customerTarget);
                this.$emit("proceed");
            },
            ...mapMutations('getStartedModule', {
                updateCustomerBase: 'updateCustomerBase'
            })
        },
        computed: {
            ...mapState('getStartedModule', {
                jobRequestValues: `jobRequestValues`
            })
        }
    };
</script>
