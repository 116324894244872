<template>
    <div class="text-center form-spacing">
        <span class="form-title">Kindly enter your contact information</span>
        <p class="mb-5">
            One of our representatives will use the information provided to reach out
            to you.</p>

        <div class="form-row mb-5">
            <div class="form-group contact-form">
                <div class="row gx-2">
                    <div class="col-md-6">
                        <form-input id="firstName"
                                    ref="firstName"
                                    placeholder="First name"
                                    :default-value="clientObject.firstName"
                                    @change="onChange"
                                    @input="firstName = $event">
                        </form-input>
                    </div>
                    <div class="col-md-6">
                        <form-input id="lastName"
                                    ref="lastName"
                                    :default-value="clientObject.lastName"
                                    placeholder="Last name"
                                    @change="onChange"
                                    @input="lastName = $event">
                        </form-input>
                    </div>
                </div>
                <div class="col-md-12">
                    <form-input id="email"
                                ref="email"
                                placeholder="Email address"
                                type="email"
                                :default-value="clientObject.email"
                                @change="onChange"
                                @input="emailAddress = $event">
                    </form-input>
                </div>
                <div class="col-md-12">
                    <phone-number ref="phoneInput"
                                  @change="onChange"
                                  :default-phone-number="clientObject.phoneNumber"
                                  @input="phoneNo = $event.formattedNumber"/>
                </div>
                <div class="col-md-12">
                    <form-input id="companyName"
                                ref="companyName"
                                placeholder="Company name"
                                :default-value="clientObject.companyName"
                                @change="onChange"
                                @input="companyName = $event">
                    </form-input>
                </div>
                <div class="col-md-12">
                    <form-input id="streetAddress"
                                ref="streetAddress"
                                placeholder="Street Address"
                                :default-value="clientObject.streetAddress"
                                @change="onChange"
                                @input="streetAddress = $event">
                    </form-input>
                </div>
                <div class="col-md-12">
                    <form-select id="countries"
                                 ref="countries"
                                 :default-value="clientObject.countryId"
                                 :options="this.countriesAndStates"
                                 label="Select a country"
                                 @change="onChange"
                                 @input="onCountrySelected">
                    </form-select>
                </div>
                <div v-if="country" class="row gx-2">
                    <div class="col-md-6">
                        <form-select id="states"
                                     ref="states"
                                     :key="country"
                                     :default-value="clientObject.stateId"
                                     :options="statesByCountry(country)"
                                     label="Select a state/region"
                                     @change="onChange"
                                     @input="state = $event">
                        </form-select>
                    </div>
                </div>
            </div>
        </div>

        <button :class="[isValid ? 'enabled' : 'disabled']" class="btn btn-primary btn-lg" type="button"
                @click="submit">
            <span class="btn__text-spacing">Submit</span> 
            <ion-icon name="arrow-forward" />
        </button>

        <div class="mb-5">
            <p>
                By submitting , you are accepting our
                <router-link to="/privacy-policy">
                    <span class="text-blue">Terms</span> and
                    <span class="text-blue">Privacy Policy</span>
                </router-link>
            </p>
        </div>
        <GoogleRecaptcha :sitekey="siteKey"/>
    </div>
</template>

<script>
    import FormInput from "../forms/FormInput";
    import PhoneNumberInput from "../forms/PhoneNumberInput";
    import FormSelect from "@/components/forms/FormSelect";
    import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
    import store from "@/store";
    import GoogleRecaptcha from 'vue2-recaptchav3';

    export default {
        name: "ContactInfo",
        components: {
            "form-select": FormSelect,
            "form-input": FormInput,
            "phone-number": PhoneNumberInput,
            GoogleRecaptcha
        },
        data() {
            return {
                firstName: null,
                lastName: null,
                emailAddress: null,
                phoneNo: {},
                companyName: null,
                streetAddress: null,
                country: null,
                state: null,
                city: null,
                isValid: false, errorMsg: null,
                siteKey: null,
            };
        },
        props: {
            selectedRequestType: {
                type: String,
                required: true,
            },
        },
        created() {
            this.siteKey = process.env.VUE_APP_SITE_KEY
        },
        destroyed() {
            this.siteKey = null
        },
        mounted() {
            if (!this.jobRequestValues.requestType) {
                this.$router.push({name: "ServicePreference"})
            }
            this.unsubscribe = store.subscribe(mutation => {
                if (mutation.type === 'getStartedModule/updateIsRegistered' && this.isRegistered) {
                    this.proceed()
                }
            });

            const total = this.selectedRequestType === "build-a-product" ? 7 : 4
            this.$emit("currentProgress", {
                current: total,
                total: total
            });
            this.setCities([])
        },
        methods: {
            validate: function () {
                this.isValid = !!(
                    this.firstName &&
                    this.lastName &&
                    this.emailAddress &&
                    this.phoneNo &&
                    this.companyName &&
                    this.streetAddress &&
                    this.country &&
                    this.state 
                );
            },

            onChange: function () {
                this.validate();
            },

            submit: function () {
                let clientObject = {
                    email: this.emailAddress,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    phoneNumber: this.phoneNo,
                    companyName: this.companyName,
                    streetAddress: this.streetAddress,
                    cityId: this.city,
                    stateId: this.state,
                    countryId: this.country,
                }

                this.setClientObject(clientObject)

                let clientJobRequestObject = {
                    email: this.emailAddress,
                    first_name: this.firstName,
                    last_name: this.lastName,
                    phone_number: this.phoneNo,
                    company_name: this.companyName,
                    street_address: this.streetAddress,
                    state_id: this.state,
                    referral_key: this.partner ? this.partner.referral_key : null
                }
                let jobRequestObject
                if (this.jobRequestValues.requestType === 'build-a-product') {
                    jobRequestObject = {
                        request_type: this.jobRequestValues.requestType,
                        is_new_product: this.jobRequestValues.productType === "new-product",
                        problem_description: this.jobRequestValues.problemStatement,
                        target_customer_base: this.jobRequestValues.customerBase,
                        tentative_period: this.jobRequestValues.timeline,
                        product_links: this.jobRequestValues.similarLinks,
                    }
                } else {
                    jobRequestObject = {
                        request_type: this.jobRequestValues.requestType,
                        client_resources: this.jobRequestValues.requiredTalents.map(item => {
                            return {
                                job_role_id: item.selectedRole,
                                number_of_talents: item.numberOfTalents,
                                skills: item.selectedStack
                            }
                        }),
                        is_remote: this.jobRequestValues.jobType === 'remote'
                    };
                }
                clientJobRequestObject = {
                    ...clientJobRequestObject,
                    ...jobRequestObject
                }
                this.createClientAndJobRequest(clientJobRequestObject)
            },
            onCountrySelected: function (event) {
                this.setCities([])
                this.country = event
            },
            resetData: function () {
                this.setJobRequestValues({})
                this.updateIsRegistered(false)
                this.setClientObject({})
                this.setPartner(null)
            },
            proceed: function () {
                this.resetData()
                this.$router.push({
                    name: "RequestSuccess",
                    params: {clientName: this.name}
                });
            },
            ...mapActions({
                getCitiesByStateId: 'getCitiesByStateId'
            }),
            ...mapActions('getStartedModule', {
                createClientAndJobRequest: 'createClientAndJobRequest'
            }),
            ...mapMutations({
                setCities: 'setCities'
            }),
            ...mapMutations('partnersModule', {
                setPartner: 'setPartner'
            }),
            ...mapMutations('getStartedModule', {
                setClientObject: 'setClientObject',
                setJobRequestValues: 'setJobRequestValues',
                updateIsRegistered: 'updateIsRegistered'
            })
        },
        computed: {
            ...mapState({
                countriesAndStates: `countriesAndStates`,
                cities: 'cities'
            }),
            ...mapState('partnersModule', {
                partner: `partner`
            }),
            ...mapState('getStartedModule', {
                jobRequestValues: `jobRequestValues`,
                clientObject: 'clientObject',
                isRegistered: 'isRegistered'
            }),
            ...mapGetters({
                statesByCountry: "statesByCountry"
            })
        }
    };
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/form-input";
</style>
