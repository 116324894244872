import axios from "axios";

const axiosInstance = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	validateStatus: function (status) {
		return !(status < 500 && status >= 600);
	}
});

axiosInstance.interceptors.request.use(config => {
	return config
});

axiosInstance.interceptors.response.use(response => {
	return response
});

export default axiosInstance;
