import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from 'vuex-persistedstate';
import baseState from "./state";
import getStartedModule from './modules/getStartedModule'
import partnersModule from './modules/PartnersModule';
import mutations from "@/store/mutations";
import actions from "@/store/actions";
import getters from "@/store/getters";

Vue.use(Vuex);

const state = baseState.getDefaultState();

const isProduction = process.env.NODE_ENV === 'production';

const store = new Vuex.Store({
	plugins: [createPersistedState()],
	modules: {
		getStartedModule,
		partnersModule
	},
	strict: isProduction,
	state,
	mutations,
	actions,
	getters
});

export default store