const config = {
    apiBaseUrl: process.env.VUE_APP_API_URL,
    clientSecret: process.env.VUE_APP_CLIENT_SECRET,
    clientId: process.env.VUE_APP_CLIENT_ID,
    appTitle: process.env.VUE_APP_TITLE,
    nodeEnv: process.env.NODE_ENV
};

export {
    config
};

export default {
    install (Vue) {
        Vue.appConfig = config;
        Vue.prototype.$appConfig = config
    },
    config
}