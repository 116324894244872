<template>
    <footer id="contact" class="footer pt-5 footer-nav">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 mb-4">
                    <p class="font-weight-bold pb-2 footer-nav-header" v-if="isSupportLogoFooter">
                        Powered By:
                    </p>
                    <router-link class="navbar-brand" :to="this.$route.path">
                        <img alt="Dufuna logo"
                            src="@/assets/img/brand/dufuna-new-logo.svg"
                            class="img-fluid"
                        />
                    </router-link>
                </div>

                <div class="col-lg-9">
                    <div class="row mb-4">
                        <div class="col-lg-4 col-md-6 mb-4">
                            <address> 75 Shelton St <br/> London WC2H 9JQ <br> Uk </address>
                            <address> 12B Fabunmi Street <br/> Ashi-Bodija, Ibadan <br> Nigeria </address>
                        </div>

                        <div class="col-lg-3 col-md-6 mb-4">
                            <ul
                                v-for="(contactInfo, index) in contactInfos"
                                :key="index"
                                class="list-unstyled mb-0"
                            >
                                <li class="mb-3 text-blue">
                                <a :href="contactInfo.href">{{ contactInfo.info }}</a>
                                </li>
                            </ul>
                        </div>
                        
                        <div class="col-lg-5 col-md-6 mb-4">
                            <div class="row">
                                <div v-for="(icon, index) in icons" 
                                    :key="index" 
                                    class="col-2 col-md-2 h2 mb-0"
                                >
                                    <span class="px-4 footer-icons">
                                    <i class="bi" :class="icon.name" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                <div class="row gx-3 mb-4">
                    <label for="newsletter" class="form-label" >Join our newsletter</label>
                    <div class="col-10 col-md-10">
                        <form-input @change="onChange" 
                                    @input="emailAddress = $event" 
                                    id="email" 
                                    placeholder="Enter your email address" 
                                    ref="email" 
                                    type="email">
                        </form-input>
                    </div>
                    <div class="col-2">
                        <router-link
                            class="btn btn-primary btn-brand-primary mb-0 footer__newsletter-link"
                            to="/"
                        >
                            <span><i class="bi bi-arrow-right" /></span>
                        </router-link>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <div class="container text-center footer__content py-3">
            <div class="footer__copyright">
                Copyright © {{ new Date().getFullYear() }} Dufuna. All rights reserved
            </div>
            <div class="footer__privacy-policy">
                <router-link to="/privacy-policy" class="pe-2">Privacy Policy</router-link>|
                <router-link to="/">Terms of Service</router-link>
            </div>
        </div>
    </footer>
</template>

<script>
import FormInput from "../forms/FormInput";
export default {
    name: "HomeFooter",
    props: {
        isSupportLogoFooter: { type: Boolean, default: false },
    },
    components: {
        "form-input": FormInput,
    },
    data() {
        return {
            contactInfos: [
                {
                info: "info@dufuna.org",
                href: "mailto:info@dufuna.org",
                },
            ],
            icons: [
                { name: "bi-medium" },
                { name: "bi-instagram" },
                { name: "bi-twitter" },
                { name: "bi-linkedin" },
                { name: "bi-facebook" },
            ],
        };
    },
    methods: {
        onChange: function () {
            this.validate();
            this.emitValue();
        },
    }
};
</script>

<style lang="scss">
@import "@/assets/scss/components/home-footer.scss";
</style>
