import index from './index'

const resetPartners = state => {
    Object.assign(state, index.getDefaultPartners());
};

const setPartner = (state, partner) => {
    state.partner = partner
}

export default {
    resetPartners,
    setPartner
}