<template>
    <div id="partners" class="justify-content-center text-center section-spacing">
        <div class="container">
            <p class="display__title text-center">Our <span class="text-blue-gradient">Partners</span></p>
            <p class="display__header-text text-center">
                <!-- Some descriptive, yet catchy explanation of the section above -->
            </p>
            <div class="row justify-content-center">
                <div v-for="(partner, index) in partners" :key="index" 
                    class="col-md-3 col-4 align-items-center mb-3 justify-content-center"
                >
                    <img :src="partner.image" :alt="partner.name" class="img-fluid partners-image" loading="lazy" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Partners",
        data: () => ({
            partners: [
                { image: require("@/assets/img/brand/ahk-nigeria-logo.png"), name: 'AHK' },
                { image: require("@/assets/img/brand/paradigm.svg"), name: 'Paradigm Initiative' }
            ]
        })
    };
</script>
