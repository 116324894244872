import Repository from "@/repo/Repository";

const resource = 'partners';
const config = {
    headers: {
        'Content-Type': 'application/json'
    }
};

const getPartnerRequirementByKey= (accessToken, partnerKey) => {
    const params = {
        access_token: accessToken,
    }
    return Repository.get(`${resource}/${partnerKey}`, {params}, config);
}

export default {
    getPartnerRequirementByKey
}