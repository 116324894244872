const responseCodes = {
	successCode: 200,
	invalidTokenCode: 401
};

const success = 'success';
const error = 'error';

export default {
	responseCodes,
	success,
	error
}