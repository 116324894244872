<template>
    <div class="testimonials section-spacing">
        <div class="container">
            <div class="row p-5 text-gray">
                <TestimonialCard extraClass="text-font-lg text-white" />
            </div>
        </div>
    </div>
</template>

<script>
import TestimonialCard from "./TestimonialCard"
export default {
    name: "Testimonials",
    components: {
        TestimonialCard
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/testimonials.scss";
</style>
