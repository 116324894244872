<template>
    <div>
        <main class="error-page__wrapper">
            <div class="container text-center">
                <img src="illustrations/404-not-found.png" :alt="pageTitle" class="error-page__img img-fluid">
                <h5 class="error-page__title">Whoops! :(</h5>
                <p class="error-page__desc">We can't seem to find the page you are looking for.</p>
                <router-link class="btn btn-primary btn-lg mb-0" :to="returnURL">
                    Back Home
                </router-link>
            </div>
        </main>
    </div>
    
</template>


<script>
import {mapState} from "vuex";

export default {
    name: "Page404",
    data() {
        return {
            pageTitle: "PAGE NOT FOUND"
        }
    },
    computed: {
        ...mapState(['returnURL'])
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/pages/error-page";
</style>
