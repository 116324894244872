import Vue from 'vue'
import App from './App.vue'
import vuex from 'vuex'
import router from './router'
import VModal from 'vue-js-modal'
import configPlugin from '../config'
import VueSmoothScroll from 'vue2-smooth-scroll'
import VueAnime from 'vue-animejs';
import Select2 from 'v-select2-component';
import VueGlide from 'vue-glide-js'

import 'bootstrap/js/dist/collapse';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'animejs';
import 'vue-glide-js/dist/vue-glide.css';

Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^ion-/]

Vue.use(vuex);
Vue.use(VModal, {dialog: true, dynamic: true});
Vue.use(configPlugin);
Vue.use(VueAnime);
Vue.use(Select2);
Vue.use(VueGlide);

Vue.use(VueSmoothScroll);

new Vue({
	router,
	render: h => h(App),
}).$mount('#app');
