<template>
    <div class="pt-4">
        <div class="form-row mb-5">
            <div class="form-group contact-form">
                <div class="col-md-12">
                    <label for="contactName" class="form-label">
                        Contact Name <span class="text-muted">(required)</span>
                    </label>
                    <form-input id="contactName"
                                ref="contactName"
                                v-model="contactName"
                                placeholder="Contact Name"
                                @change="onChange"
                                @input="contactName = $event">
                    </form-input>
                </div>
                <div class="col-md-12">
                    <label for="companyName" class="form-label">
                        Business Name <span class="text-muted">(required)</span>
                    </label>
                    <form-input id="companyName"
                                ref="companyName"
                                placeholder="Business name"
                                @change="onChange"
                                @input="companyName = $event">
                    </form-input>
                </div>
                <div class="col-md-12">
                    <label for="emailAddress" class="form-label">
                        Email Address <span class="text-muted">(required)</span>
                    </label>
                    <form-input id="email"
                                ref="email"
                                placeholder="Email address"
                                type="email"
                                @change="onChange"
                                @input="emailAddress = $event">
                    </form-input>
                </div>
                <div class="col-md-12">
                    <label for="phoneInput" class="form-label">
                        Phone Number <span class="text-muted">(required)</span>
                    </label>
                    <phone-number ref="phoneInput"
                                  @change="onChange"
                                  @input="phoneNo = $event.formattedNumber"/>
                </div>
                <div class="col-md-12" >
                    <label for="phoneInput" class="form-label">Country</label>
                    <select v-model="country" :style="{height: ' 55px'}" class="form-select form-select-md mb-4" aria-label="Default select example">
                        <option v-for="(countrySelected, index) in countries " :key="index" v-bind:value="countrySelected.id">{{countrySelected.name}}</option>
                    </select>
                </div>

                <div class="col-md-12">
                    <label for="phoneInput" class="form-label">Industry Category</label>
                    <select v-model="industry" :style="{height: ' 55px'}" class="form-select form-select-md mb-4" aria-label="Default select example">
                      <option v-for="(industrySelected, index) in industries " :key="index" v-bind:value="industrySelected.id">{{industrySelected.name}}</option>
                    </select>
                </div>

                <div class="col-md-12 mb-4">
                    <label for="services" class="form-label">Which of the service(s) are you interested in?</label>
                    <div class="form-check service-input" :key="index" v-for="(service, index) in services">
                        <input
                            class="form-check-input"
                            :id="service.id"
                            :value="service.id"
                            type="checkbox"
                            @change="onChange"
                            v-model="selectedService"
                        />
                        <label
                            class="form-check-label"
                            :for="service.value">
                            <span class="px-2">{{ service.label }}</span>
                        </label>
                    </div>
                </div>

                <div class="col-md-12 mb-3">
                    <label for="additionalInfo" class="form-label">
                        Additional Information <span class="text-muted">(Optional)</span>
                    </label>
                    <textarea class="form-control" v-model="additionalInfo" aria-label="With textarea" placeholder="Type something long here" rows="6"></textarea>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center align-items-center">
            <button class="btn custom-spacing" :class="[isValid ? 'enabled btn-brand-primary' : 'disabled btn-secondary']" type="button" @click="submit">
                Submit
            </button>
            <span class="p-2"><i class="bi bi-check-circle" :class="[isValid ? 'text-green' : 'd-none']" /></span>
        </div>

        <GoogleRecaptcha :sitekey="siteKey"/>
    </div>
</template>

<script>
import FormInput from "../forms/FormInput";
import PhoneNumberInput from "../forms/PhoneNumberInput";
import {mapActions, mapGetters} from "vuex";
import GoogleRecaptcha from 'vue2-recaptchav3';

export default {
    name: "RegisterForm",
    components: {
        "form-input": FormInput,
        "phone-number": PhoneNumberInput,
        GoogleRecaptcha
    },
    data() {
        return {
            contactName: null,
            companyName: null,
            emailAddress: null,
            phoneNo: {},
            country: null,
            countries: [],
            industry: null,
            industries: [],
            selectedService: [],
            services: [
                {
                    id: "hire-talents",
                    label: "Hire talent(s) to join your team"
                },
                {
                    id: "build-product",
                    label: "Build your product(s) with us"
                }
            ],
            additionalInfo: null,
            isValid: false,
            errorMsg: null,
            siteKey: null,
        };
    },
    created() {
        this.siteKey = process.env.VUE_APP_SITE_KEY
    },
    methods: {
      ...mapActions({postJobRequestData: "getStartedModule/createClientAndJobRequest"}),
        validate: function () {
            this.isValid = !!(
                this.contactName &&
                this.companyName &&
                this.emailAddress &&
                this.phoneNo &&
                this.country &&
                this.industry &&
                this.selectedService.length
            );
        },

      emitValue: function () {
            this.$emit("input", {
                contactName: this.contactName,
                companyName: this.companyName,
                email: this.emailAddress,
                phoneNo: this.phoneNo,
                country: this.country,
                industry: this.industry,
                selectedService: this.selectedService,
                additionalInfo: this.additionalInfo,
            });
        },

        onChange: function () {
            this.validate();
            this.emitValue();
        },

        submit: function () {
          const body =  {
            email: this.emailAddress,
            contact_name: this.contactName,
            business_name: this.companyName,
            phone_number: this.phoneNo,
            additional_information: this.additionalInfo,
            industry_category_id: this.industry,
            country_id: this.country,
            request_type: this.selectedService.length == 2 ? `${this.selectedService[0]} & ${this.selectedService[1]}` : this.selectedService[0]
          }
          this.postJobRequestData(body);
        },
    },
    mounted() {
      this.industries = this.getIndustryCategoryGetters;
      this.countries = this.getCountryGetters;
    },
    computed: {
      ...mapGetters({getCountryGetters: "countriesGetters"}),
      ...mapGetters({getIndustryCategoryGetters: "industryCategoryGetters"}),
    },
    destroyed() {
        this.siteKey = null
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/components/form-input";
</style>