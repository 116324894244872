<template>
    <section class="header bg-light-purple">
        <nav class="navbar navbar-expand-md py-3">
            <div class="container">
                <router-link  class="navbar-brand" :to="returnURL">
                    <img v-if="isPartner" alt="Dufuna logo" :src="partnersLogo" class="header__img"/>
                    <img v-else
                         alt="Dufuna logo"
                         :src="require('@/assets/img/brand/' + partnersLogo )"
                         class="header__img"/>
                </router-link>
                <span class="navbar-toggler" data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse" type="button">
                    <i class="bi bi-list fs-2 text-black" />
                </span>
                <div id="navbarSupportedContent" class="collapse navbar-collapse justify-content-end">
                    <ul v-if="isHomePage" class="navbar-nav ml-lg-auto">
                        <li v-for="(link, index) in links" :key="index" class="nav-item text-black">
                            <a v-smooth-scroll="{ duration: 2000 }" :href="link.href"
                               :class="linkClass">{{ link.input }}</a>
                        </li>
                        <li class="nav-item text-black">
                            <a :class="linkClass" :href="'//' + learningUrl">Learning <i class="bi bi-chevron-down"></i></a>
                        </li>
                    </ul>
                </div>
                <div v-if="isHomePage" id="navbarSupportedContent" class="collapse navbar-collapse mt-3 mt-lg-0 justify-content-end">
                    <router-link class="btn btn-primary btn-brand-primary btn-lg mb-0 custom-spacing"
                                    to="/sign-up"
                                    :style="{backgroundColor: partnerColor, borderColor: partnerColor}">
                        Get started
                    </router-link>
                </div>
            </div>
        </nav>
    </section>
</template>

<script>
    import {mapState, mapActions} from "vuex";

    export default {
        name: "HomeHeader",
        props: {
            isPartner: { type: Boolean, default: false },
            isHomePage: { type: Boolean, default: true },
            partnersLogo: {
                type: String,
                default: "dufuna-new-logo.svg"
            },
            linkClass: String,
            iconColor: {type: String, default: "dark"},
            links: Array ,
            partnerColor: String
        },
        data() {
            return {
                learningUrl: process.env.VUE_APP_LEARNING_WEB_URL
            }
        },
        computed: {
            ...mapState(['returnURL'])
        },
      methods: {
        ...mapActions({getAuthTokenAction: "getAuthToken"}),
        ...mapActions({countriesData: "getCountries"}),
        ...mapActions({industryCategoryData: "getIndustryCategory"}),
      },
      mounted() {
        this.getAuthTokenAction();
        setTimeout(  () => {
          this.countriesData();
          this.industryCategoryData();
        }, 2000);
      }
    };
</script>

<style lang="scss" scoped>
@import "../../assets/scss/components/home-header.scss";
</style>
