<template>
    <div class="container section-spacing talents-pool">
        <div class="d-flex align-items-center">
            <div>
                <button class="btn btn-link text-blue" @click.prevent="slidePrev">
                    <i class="bi bi-chevron-left text-blue-gradient"></i>
                </button>
            </div>
            <hooper ref="carousel" style="height: 324px" :itemsToShow="4" :centerMode="true" :infiniteScroll="true"
                :autoPlay="true" :playSpeed="4000" :wheelControl="false" :hoverPause="false">
                <slide v-for="(talent, index) in talents" :key="index">
                    <div class="card talents-body p-0 h-100">
                        <div>
                            <div class="talents-img">
                                <img :alt="talent.jobTitle" :src="talent.image" class="img-fluid" />
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <span class="card__header-text">{{ talent.name }}</span>
                                    <span class="card__sub-text">{{ talent.jobTitle }}</span>
                                    <span class="card__sub-text">{{ talent.company }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </slide>
            </hooper>
        </div>
    </div>
</template>

<script>
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";

export default {
    name: "TalentsPool",
    props: {
        partnerColor: String,
        talents: Array
    },
    components: {
        Hooper,
        Slide,
    },
    methods: {
        slidePrev() {
            this.$refs.carousel.slidePrev();
        },
    }
};
</script>

<style lang="scss">
@import "@/assets/scss/components/talents-pool.scss";
</style>
