import mutations from './mutations'
import actions from "./actions";

const getDefaultPartners = () => {
    return {
        partner: null
    }
};

const state = getDefaultPartners();

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getDefaultPartners
}