function validateEmail(email) {
	// eslint-disable-next-line no-useless-escape
	let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	return re.test(email);
}

function validateUrl(url) {
	let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
		'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

	return !!pattern.test(url);
}

function catchNetworkError(context) {
	context.commit('updateError', {
			title: "A network error has occurred",
			message: "Please check your internet and try again."
		},
		{root: true});
}

function showGeneralError(context, message) {
	showError(context, message, 'An error has occurred')
}

function onAuthTokenExpired(context, action, payload) {
	context.dispatch('getAuthToken', {
			action: action,
			isScope: false,
			param: payload
		},
		{root: true});
}

function showInternalError(context) {
	showGeneralError(context, "Oops, something went wrong. " +
		"There was an internal error, our engineers have been" +
		" notified and would resolve this shortly.")
}

function showError(context, message, title) {
	context.commit('updateError',
		{title: title, message: message},
		{root: true});
}

function showAlert(store, payload) {
	store.commit('updateAlert',
		{
			title: payload.title,
			message: payload.message,
			buttons: payload.buttons
		},
		{root: true});
}

export default {
	validateEmail,
	validateUrl,
	catchNetworkError,
	showGeneralError,
	onAuthTokenExpired,
	showInternalError,
	showError,
	showAlert,
}