<template>
    <section class="talents section-spacing">
        <div class="container">
            <div class="row">
                <div class="col-xl-10 mx-auto text-center">
                    <div class="row justify-content-center">
                        <p class="display__title pb-3">
                        <span class="text-blue">Hire</span> from our pool of
                        <span class="text-light-purple">talents.</span>
                        </p>
                        <p class="display__header-text talents__sub-text">
                        {{ description }}
                        </p>
                    </div>
                    <div class="row">
                        <div
                            v-for="(techTalent, index) in techTalents"
                            :key="index"
                            class="col-12 col-lg-4 col-md-6 mb-3 d-flex align-items-stretch"
                        >
                        <div class="card flex-fill">
                            <div class="card-body px-0">
                            <span class="icons text-blue-gradient">
                                <i class="bi" :class="techTalent.classIcon" />
                            </span>
                            <h5 class="card__header-text mt-5 talents__spacing pt-5">
                                {{ techTalent.cardTitle }}
                            </h5>
                            <p class="card__sub-text mb-0">{{ techTalent.cardText }}</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "OurTalents",
    data: () => ({
        description: "A trusted network of early career professionals in Product Design, Product Management and Software Development.",
        techTalents: [
            {
                classIcon: "bi-code-slash",
                cardTitle: "Software Developer",
                cardText:
                "Frontend, Backend and Full-stack developers skilled in various modern software development technologies",
            },
            {
                classIcon: "bi-bar-chart-line",
                cardTitle: "Product Analyst",
                cardText:
                "Digital Project Managers and Analysts with expertise in numerous PM tools and frameworks",
            },
            {
                classIcon: "bi-palette",
                cardTitle: "Product Designer",
                cardText:
                "UI/UX designers experienced in conducting user research and designing beautiful and intuitive products that excite users",
            },
        ],
    }),
};
</script>
