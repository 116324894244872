<template>
    <div>
        <div v-for="(testimonial, index) in testimonials" :key="index">
            <span class="symbol text-blue-gradient">&#8220;</span>
            <p class="testimonials__card-text pb-4" :class="extraClass" >{{ testimonial.text }}</p>
            <img :src="testimonial.image" alt="Testimonial logo">
        </div>
    </div>
</template>

<script>
export default {
    name: "TestimonialCard",
    props: {
        extraClass: {
            type: String,
            default: "text-white text-font-xlg"
        },
    },
    data: () => ({
        testimonials: [
            {
                image: require("@/assets/img/brand/sheworkswhite.png"),
                text: `We reached out to Dufuna to design and develop a website for 
                    our social initiative, SheWorks Africa. Their engagement was highly 
                    professional and client-focused. They advised on the most effective 
                    way of achieving our goals and delivered a quality website. The dedicated 
                    project/account manager also ensured that all requirements were met to our 
                    satisfaction.`,
                name: "Mary Adewale",
                position: "Founder & CEO, Jobily",
                color: "color-blue"
            },
        ],
    }),
}
</script>

<style lang="scss">
@import "@/assets/scss/components/testimonials.scss";
</style>