<template>
    <section class="bg-main">
        <div class="container">
            <div class="row hero section-spacing">
                <div class="col-md-5 col-lg-4 pt-3 col-sm">
                    <div class="mb-3">
                        <div class="hire">
                            <p class="hero__heading">
                                <span class="text-blue-gradient">Hire Exceptional</span> Early Career Tech Professionals
                            </p>
                            <p class="hero__sub-text">Vetted and supervised by top tech experts.</p>
                        </div>
                    </div>

                    <router-link class="btn btn-primary btn-brand-primary btn-lg my-2 custom-spacing" to="/sign-up"
                        :style="{ backgroundColor: bgColor, borderColor: bgColor }">
                        Get started
                        <span class="btn__icons"><i class="bi bi-arrow-up-right" /></span>
                    </router-link>

                    <div class="mt-4 d-flex text-medium-gray gx-3">
                        <span class="pe-3"><i class="bi bi-check-circle pe-1"></i>Trusted</span>
                        <span class="pe-3"><i class="bi bi-building pe-1"></i>Industry ready</span>
                    </div>

                    <div class="mt-3" v-if="isSupportLogo">
                        <p class="hero__support-text text-soft-black">Powered by:</p>
                        <router-link class="navbar-brand" :to="{ name: 'home' }">
                            <img alt="Dufuna marketplace logo" src="/brands/dufuna-talent-logo2.svg" />
                        </router-link>
                    </div>
                </div>
                <div class="col-md-7 col-lg-8">
                    <div class="hero-banner">
                        <hooper :vertical="true" class="background-div" style="height: 324px" :itemsToShow="1" :centerMode="true" :autoPlay="true"
                        :playSpeed="4000" :infiniteScroll="true" :hoverPause="false">
                            <slide v-for="(talent, index) in talents" :key="index">
                                    <img :src="talent.heroImage" alt="" class="img-fluid rounded-end-circle heroImg" />
                                    <div class="hero-content">
                                        <div class="text-container">
                                            <span class="card__header-text">{{ talent.name }}</span>
                                            <span>{{ talent.company }}</span>
                                        </div>
                                        <div class="image-container">
                                            <div>
                                                <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="3.5" cy="3" r="3" fill="white"/>
                                                </svg>
                                                <span class="m-0 p-0">{{ index + 1 }}</span>
                                                <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="3.5" cy="3" r="3" fill="#B0B0B0"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                            </slide>
                        </hooper>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";

export default {
    name: "Hero",
    props: {
        isSupportLogo: { type: Boolean, default: false },
        bgColor: String,
        talents: Array,
    },
    components: {
        Hooper,
        Slide,
    },
    data: () => {
        return {
            partners: true,
        };
    },
};
</script>
